import React from 'react';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import uuid from 'uuid/v4';
import cloneDeep from 'lodash.clonedeep';
import { getImageDimensions } from 'js/shared/helpers/image';
import {
  START_IMAGE_CROP,
  loadImageCrop,
  CONFIRM_IMAGE_CROP,
  confirmImageCropSuccess,
  confirmImageCropFailed,
  CONFIRM_CROP_WARNING,
  startImageCrop as startImageCropAction
} from 'js/actionCreators/croppingActions';
import { updateScribe } from 'js/actionCreators/scribeActions';
import { showError } from 'js/actionCreators/uiActions';
import { setUserSetting } from 'js/actionCreators/userSettingsActions';
import { SUPPRESS_SVG_CROP_WARNING } from 'js/config/userSettingsKeys';
import config from 'js/config/config';

import { sendErrorToSentry } from '../logging';

import { getActiveElements, getImageCropData, getScribeById } from './selectors';

export function* startImageCrop({ scribeId, elementId, sceneId }) {
  const scribe = yield select(getScribeById, scribeId);
  const element = scribe.elements.find(el => el.id === elementId);
  const { scaleX = 1, scaleY = 1, flipX = false, flipY = false } = element;

  if (element) {
    const imageUrl = element._imageUrl;
    const filename = element.image.filename;

    yield put(
      loadImageCrop({
        scribeId,
        elementId,
        sceneId,
        imageUrl,
        transforms: {
          scaleX: Number(scaleX),
          scaleY: Number(scaleY),
          flipX,
          flipY
        },
        filename,
        imageType: element.image.contentType
      })
    );
  }
}

export function* confirmImageCrop() {
  const { scribeId, elementId, sceneId, crop, filename } = yield select(getImageCropData);
  const { FAQ_URL } = config;

  if (!crop) {
    return yield put(confirmImageCropSuccess());
  }

  try {
    const newCropImageAsset = yield call(appServices.uploadAsset, { file: crop.blob, filename });
    const newCropImageAssetUrl = yield call(appServices.getAssetUrl, newCropImageAsset);
    const scribe = yield select(getScribeById, scribeId);
    const scribeClone = cloneDeep(scribe);
    const imageDimensions = yield call(getImageDimensions, newCropImageAssetUrl, crop.blob.type);
    const elementToUpdate = scribeClone.elements.find(el => el.id === elementId);

    const newElementId = uuid();
    const sceneToUpdate = scribeClone.scenes.find(scene => scene.id === sceneId);
    const sceneElementIndex = sceneToUpdate.elementIds.findIndex(id => id === elementId);
    sceneToUpdate.elementIds[sceneElementIndex] = newElementId;

    elementToUpdate._imageUrl = newCropImageAssetUrl;
    elementToUpdate.id = newElementId;
    elementToUpdate.width = imageDimensions.width;
    elementToUpdate.height = imageDimensions.height;

    elementToUpdate.flipX = false;
    elementToUpdate.flipY = false;
    elementToUpdate.scaleX = elementToUpdate.scaleX / (crop.options.imageScaleFactor ?? 1);
    elementToUpdate.scaleY = elementToUpdate.scaleX;
    elementToUpdate.x += crop.options.left * elementToUpdate.scaleX;
    elementToUpdate.y += crop.options.top * elementToUpdate.scaleX;

    delete elementToUpdate._recolorDefaults;
    delete elementToUpdate.recolorAvailable;

    elementToUpdate.image = {
      contentType: crop.blob.type,
      assetId: newCropImageAsset,
      provider: 'user',
      filename
    };

    yield put(updateScribe(scribeClone));
    yield put(confirmImageCropSuccess());
  } catch (error) {
    yield put(confirmImageCropFailed(error));
    yield put(
      showError({
        message: <span>Sorry, there was an error applying your crop. Please try cropping your image again.</span>,
        description: (
          <span>
            If you are still encountering a problem, please
            <a href={FAQ_URL} className="flash__text-link" target="_blank" rel="noopener noreferrer">
              {' '}
              contact our support team.
            </a>
          </span>
        )
      })
    );
    sendErrorToSentry(error);
  }
}

function* confirmCropWarning({ scribeId, suppressWarning }) {
  if (suppressWarning) {
    yield put(setUserSetting({ setting: SUPPRESS_SVG_CROP_WARNING, value: true, scribeId }));
  }

  const scribe = yield select(getScribeById, scribeId);
  const activeScene = scribe.scenes.find(scene => scene.active);
  const activeElements = yield select(getActiveElements);

  yield put(
    startImageCropAction({
      scribeId,
      sceneId: activeScene.id,
      elementId: activeElements[0]
    })
  );
}

export default function* croppingSagas() {
  yield takeLatest(START_IMAGE_CROP, startImageCrop);
  yield takeLatest(CONFIRM_IMAGE_CROP, confirmImageCrop);
  yield takeLatest(CONFIRM_CROP_WARNING, confirmCropWarning);
}
