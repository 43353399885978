import * as PIXI from 'pixi.js';

import vscWatermarkSVG from '../../../../../imgs/url-loaded/vs-watermark.svg';
import { WATERMARK_CORNER_PADDING as CORNER_PADDING } from '../../../../config/defaults';

const WATERMARK_WIDTH = 818;
const WATERMARK_HEIGHT = 250;
const RATIO = WATERMARK_WIDTH / WATERMARK_HEIGHT;
const CORNER_WATERMARK_WIDTH = 500;
const CORNER_WATERMARK_HEIGHT = CORNER_WATERMARK_WIDTH / RATIO;

const addWatermarkElements = (stage, stageWidth, stageHeight) => {
  const cornerWatermarks = [
    {
      anchor: [0, 0],
      x: CORNER_PADDING,
      y: CORNER_PADDING,
      name: 'watermark_top_left'
    },
    {
      anchor: [1, 0],
      x: stageWidth - CORNER_PADDING,
      y: CORNER_PADDING,
      name: 'watermark_top_right'
    },
    {
      anchor: [0, 1],
      x: CORNER_PADDING,
      y: stageHeight - CORNER_PADDING,
      name: 'watermark_bottom_left'
    },
    {
      anchor: [1, 1],
      x: stageWidth - CORNER_PADDING,
      y: stageHeight - CORNER_PADDING,
      name: 'watermark_bottom_right'
    }
  ];

  const watermarkTexture = new PIXI.Texture.from(vscWatermarkSVG);
  const mainWatermark = new PIXI.Sprite(watermarkTexture);
  mainWatermark.name = 'watermark';
  mainWatermark.anchor.set(0.5);
  mainWatermark.x = stageWidth / 2;
  mainWatermark.y = stageHeight / 2;
  mainWatermark.width = WATERMARK_WIDTH;
  mainWatermark.height = WATERMARK_HEIGHT;

  stage.addChild(mainWatermark);

  cornerWatermarks.forEach(config => {
    const cornerWatermark = new PIXI.Sprite(watermarkTexture);
    const { anchor, x, y, name } = config;
    cornerWatermark.width = CORNER_WATERMARK_WIDTH;
    cornerWatermark.height = CORNER_WATERMARK_HEIGHT;
    cornerWatermark.name = name;
    cornerWatermark.anchor.set(...anchor);
    cornerWatermark.x = x;
    cornerWatermark.y = y;
    stage.addChild(cornerWatermark);
  });
};

export default addWatermarkElements;
