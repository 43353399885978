import { IMAGE_PROVIDER_TYPES } from 'js/config/consts';
import {
  ImageCategory,
  ScribeImageElementModelProps,
  SearchResult,
  SearchService,
  UploadProvider,
  VSCAssetData,
  VSCAssetImageData,
  VSLibraryImage,
  RatingOption,
  AddAIGenerationTrigger
} from 'js/types';

export const ADD_USER_UPLOAD_IMAGE_TO_CANVAS = 'ADD_USER_UPLOAD_IMAGE_TO_CANVAS';
export const GET_USER_IMAGES_REQUESTED = 'GET_USER_IMAGES_REQUESTED';
export const GET_USER_IMAGES_FAILURE = 'GET_USER_IMAGES_FAILURE';
export const GET_USER_IMAGES_SUCCESS = 'GET_USER_IMAGES_SUCCESS';
export const ADD_USER_IMAGE_TO_SCRIBE = 'ADD_USER_IMAGE_TO_SCRIBE';
export const DELETE_USER_IMAGE_REQUESTED = 'DELETE_USER_IMAGE_REQUESTED';
export const DELETE_USER_IMAGE_FAILURE = 'DELETE_USER_IMAGE_FAILURE';
export const DELETE_USER_IMAGE_SUCCESS = 'DELETE_USER_IMAGE_SUCCESS';
export const GET_IMAGE_LIBRARIES = 'GET_IMAGE_LIBRARIES';
export const GET_IMAGE_LIBRARIES_SUCCESS = 'GET_IMAGE_LIBRARIES_SUCCESS';
export const GET_IMAGE_LIBRARIES_FAILURE = 'GET_IMAGE_LIBRARIES_FAILURE';
export const GET_IMAGE_LIBRARY = 'GET_IMAGE_LIBRARY';
export const GET_IMAGE_LIBRARY_SUCCESS = 'GET_IMAGE_LIBRARY_SUCCESS';
export const GET_IMAGE_LIBRARY_FAILURE = 'GET_IMAGE_LIBRARY_FAILURE';
export const SEARCH_IMAGE_SUCCESS = 'SEARCH_IMAGE_SUCCESS';
export const SEARCH_IMAGE = 'SEARCH_IMAGE';
export const SEARCH_IMAGE_FAILURE = 'SEARCH_IMAGE_FAILURE';
export const ADD_LIBRARY_IMAGE_TO_CANVAS = 'ADD_LIBRARY_IMAGE_TO_CANVAS';
export const UPLOAD_IMAGE_FILES = 'UPLOAD_IMAGE_FILES';
export const UPLOAD_LIBRARY_IMAGE_ASSET = 'UPLOAD_LIBRARY_IMAGE_ASSET';
export const UPLOAD_IMAGE_ASSET_ON_LOAD = 'UPLOAD_IMAGE_ASSET_ON_LOAD';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED';
export const UPLOAD_USER_IMAGE_ASSET = 'UPLOAD_USER_IMAGE_ASSET';
export const REPLACE_USER_IMAGE_ELEMENT = 'REPLACE_USER_IMAGE_ELEMENT';
export const REPLACE_LIBRARY_IMAGE_ELEMENT = 'REPLACE_LIBRARY_IMAGE_ELEMENT';
export const REPLACE_UPLOADED_IMAGE_ELEMENT = 'REPLACE_UPLOADED_IMAGE_ELEMENT';
export const LOAD_USER_IMAGES_SLICE_SUCCESS = 'LOAD_USER_IMAGES_SLICE_SUCCESS';
export const LOAD_MORE_USER_IMAGES = 'LOAD_MORE_USER_IMAGES';
export const CLOSE_IMAGE_LIBRARY = 'CLOSE_IMAGE_LIBRARY';
export const SET_SEARCH_MEMO = 'SET_SEARCH_MEMO';
export const LOAD_USER_AI_IMAGES_SLICE_SUCCESS = 'LOAD_USER_AI_IMAGES_SLICE_SUCCESS';
export const LOAD_MORE_USER_AI_IMAGES = 'LOAD_MORE_USER_AI_IMAGES';
export const FULLY_VECTORIZE_IMAGE_AND_REPLACE_ELEMENT = 'FULLY_VECTORIZE_IMAGE_AND_REPLACE_ELEMENT';

export interface SetSearchMemoAction {
  type: typeof SET_SEARCH_MEMO;
  searchMemo?: { searchTerm: string; searchService: string };
}

export const setSearchMemo = (searchMemo?: { searchTerm: string; searchService: string }): SetSearchMemoAction => ({
  type: SET_SEARCH_MEMO,
  searchMemo
});

export interface AddUserImageToCanvasAction {
  type: typeof ADD_USER_UPLOAD_IMAGE_TO_CANVAS;
  file: File;
  filename: string;
  scribeId: number;
  canvasSize: { width: number; height: number };
  uploadMethod: string;
}

export const addUserImageToCanvas = (
  file: File,
  filename: string,
  scribeId: number,
  canvasSize: { width: number; height: number },
  uploadMethod: string
): AddUserImageToCanvasAction => ({
  type: ADD_USER_UPLOAD_IMAGE_TO_CANVAS,
  file,
  filename,
  scribeId,
  canvasSize,
  uploadMethod
});

export const getUserImages = () => ({
  type: GET_USER_IMAGES_REQUESTED
});

export interface GetUserImagesFailureAction {
  type: typeof GET_USER_IMAGES_FAILURE;
  error: Error;
}

export const getUserImagesFailure = (error: Error): GetUserImagesFailureAction => ({
  type: GET_USER_IMAGES_FAILURE,
  error
});

export interface GetUserImagesSuccessAction {
  type: typeof GET_USER_IMAGES_SUCCESS;
  payload: Array<VSCAssetData>;
}

export const getUserImagesSuccess = (payload: Array<VSCAssetData>): GetUserImagesSuccessAction => ({
  type: GET_USER_IMAGES_SUCCESS,
  payload
});

export interface ReplaceUserImageElementAction {
  type: typeof REPLACE_USER_IMAGE_ELEMENT;
  imageId: number;
  imageType: string;
  scribeId: number;
  provider: UploadProvider;
  canvasSize: { height: number; width: number };
  selectedLibrary: string;
  filename: string;
  elementId: string;
}

export const replaceUserImageElement = ({
  imageId,
  imageType,
  scribeId,
  canvasSize,
  selectedLibrary,
  filename,
  elementId
}: Omit<ReplaceUserImageElementAction, 'type' | 'provider'>): ReplaceUserImageElementAction => ({
  type: REPLACE_USER_IMAGE_ELEMENT,
  imageId,
  imageType,
  scribeId,
  provider: IMAGE_PROVIDER_TYPES.USER,
  canvasSize,
  selectedLibrary,
  filename,
  elementId
});

export interface ReplaceLibraryImageElementAction {
  type: typeof REPLACE_LIBRARY_IMAGE_ELEMENT;
  imageId: string;
  imageType: string;
  scribeId: number;
  provider: UploadProvider;
  canvasSize: { height: number; width: number };
  selectedLibrary: string;
  filename: string;
  elementId: string;
}

export const replaceLibraryImageElement = ({
  imageId,
  imageType,
  scribeId,
  provider,
  canvasSize,
  selectedLibrary,
  filename,
  elementId
}: Omit<ReplaceLibraryImageElementAction, 'type'>): ReplaceLibraryImageElementAction => ({
  type: REPLACE_LIBRARY_IMAGE_ELEMENT,
  imageId,
  imageType,
  scribeId,
  provider,
  canvasSize,
  selectedLibrary,
  filename,
  elementId
});

export interface ReplaceUploadedImageElementAction {
  type: typeof REPLACE_UPLOADED_IMAGE_ELEMENT;
  imageType: string;
  scribeId: number;
  provider: UploadProvider;
  canvasSize?: { height: number; width: number };
  filename: string;
  elementId: string;
  file: File;
}

export const replaceUploadedImageElement = ({
  imageType,
  scribeId,
  canvasSize,
  filename,
  elementId,
  file
}: Omit<ReplaceUploadedImageElementAction, 'type' | 'provider'>): ReplaceUploadedImageElementAction => ({
  type: REPLACE_UPLOADED_IMAGE_ELEMENT,
  imageType,
  scribeId,
  provider: IMAGE_PROVIDER_TYPES.LIBRARY,
  canvasSize,
  filename,
  elementId,
  file
});

export interface AddUserImageToScribeAction {
  type: typeof ADD_USER_IMAGE_TO_SCRIBE;
  imageId: number;
  imageType: string;
  scribeId: number;
  provider: UploadProvider;
  canvasSize: { height: number; width: number };
  selectedLibrary: { title: string };
  filename: string;
  rating?: RatingOption;
  eventTrigger?: AddAIGenerationTrigger;
}

export const addUserImageToScribe = (
  imageId: number,
  imageType: string,
  scribeId: number,
  canvasSize: { height: number; width: number },
  selectedLibrary: { title: string },
  filename: string,
  provider = IMAGE_PROVIDER_TYPES.USER,
  rating?: RatingOption,
  eventTrigger?: AddAIGenerationTrigger
): AddUserImageToScribeAction => ({
  type: ADD_USER_IMAGE_TO_SCRIBE,
  imageId,
  imageType,
  scribeId,
  provider,
  canvasSize,
  selectedLibrary,
  filename,
  rating,
  eventTrigger
});

export interface AddLibraryImageToCanvasAction {
  type: typeof ADD_LIBRARY_IMAGE_TO_CANVAS;
  imageId: string;
  imageType: string;
  scribeId: number;
  provider: UploadProvider;
  canvasSize: { height: number; width: number };
  selectedLibrary: string;
  filename: string;
}

export const addLibraryImageToCanvas = (
  imageId: string,
  imageType: string,
  scribeId: number,
  canvasSize: { height: number; width: number },
  selectedLibrary: string,
  filename: string,
  provider: UploadProvider
): AddLibraryImageToCanvasAction => ({
  type: ADD_LIBRARY_IMAGE_TO_CANVAS,
  imageId,
  imageType,
  scribeId,
  provider,
  canvasSize,
  selectedLibrary,
  filename
});

export interface DeleteUserImageAction {
  type: typeof DELETE_USER_IMAGE_REQUESTED;
  imageId: number;
}

export const deleteUserImage = (imageId: number): DeleteUserImageAction => ({
  type: DELETE_USER_IMAGE_REQUESTED,
  imageId
});

export interface DeleteUserImageFailure {
  type: typeof DELETE_USER_IMAGE_FAILURE;
  error: Error;
}

export const deleteUserImageFailure = (error: Error): DeleteUserImageFailure => ({
  type: DELETE_USER_IMAGE_FAILURE,
  error
});

export interface GetImageLibrariesAction {
  type: typeof GET_IMAGE_LIBRARIES;
}
export const getImageLibraries = () => ({
  type: GET_IMAGE_LIBRARIES
});

export interface GetImageLibrariesSuccessAction {
  type: typeof GET_IMAGE_LIBRARIES_SUCCESS;
  libraries: Array<ImageCategory>;
  nounProjectLibraries: Array<ImageCategory>;
}

export const getImageLibrariesSuccess = (
  libraries: Array<ImageCategory>,
  nounProjectLibraries: Array<ImageCategory>
): GetImageLibrariesSuccessAction => ({
  type: GET_IMAGE_LIBRARIES_SUCCESS,
  libraries,
  nounProjectLibraries
});

export interface GetImageLibrariesFailureAction {
  type: typeof GET_IMAGE_LIBRARIES_FAILURE;
  error: Error;
}

export const getImageLibrariesFailure = (error: Error): GetImageLibrariesFailureAction => ({
  type: GET_IMAGE_LIBRARIES_FAILURE,
  error
});

export interface GetImageLibraryAction {
  type: typeof GET_IMAGE_LIBRARY;
  libraryId: string;
  searchService: SearchService;
  cursor: string | undefined;
  libraryName: string;
}

export const getImageLibrary = (
  libraryId: string,
  searchService: SearchService,
  cursor: string | undefined,
  libraryName: string
): GetImageLibraryAction => ({
  type: GET_IMAGE_LIBRARY,
  libraryId,
  searchService,
  cursor,
  libraryName
});

export interface GetImageLibrarySuccessAction {
  type: typeof GET_IMAGE_LIBRARY_SUCCESS;
  libraryId: string;
  library: Array<VSLibraryImage>;
  searchService: SearchService;
  cursor: string;
}

export const getImageLibrarySuccess = (
  libraryId: string,
  library: Array<VSLibraryImage>,
  searchService: SearchService,
  cursor: string
): GetImageLibrarySuccessAction => ({
  type: GET_IMAGE_LIBRARY_SUCCESS,
  libraryId,
  library,
  searchService,
  cursor
});

export interface GetImageLibraryFailureAction {
  type: typeof GET_IMAGE_LIBRARY_FAILURE;
  error: Error;
}

export const getImageLibraryFailure = (error: Error): GetImageLibraryFailureAction => ({
  type: GET_IMAGE_LIBRARY_FAILURE,
  error
});

export interface DeleteUserImageSuccessAction {
  type: typeof DELETE_USER_IMAGE_SUCCESS;
  imageId: number;
}

export const deleteUserImageSuccess = (imageId: number): DeleteUserImageSuccessAction => ({
  type: DELETE_USER_IMAGE_SUCCESS,
  imageId
});

export interface SearchImagesAction {
  type: typeof SEARCH_IMAGE;
  query: string;
  searchService: SearchService;
  offset: number | undefined;
  next: string | undefined;
}

export const searchImages = (
  searchService: SearchService,
  query: string,
  offset: number | undefined,
  next: string | undefined
): SearchImagesAction => ({
  type: SEARCH_IMAGE,
  searchService,
  query,
  offset,
  next
});

export interface SearchImagesSuccessAction {
  type: typeof SEARCH_IMAGE_SUCCESS;
  searchService: SearchService;
  results: Array<SearchResult>;
  totalImages: number;
  query: string;
  next: string | undefined;
}

export const searchImagesSuccess = (
  searchService: SearchService,
  results: Array<SearchResult>,
  totalImages: number,
  query: string,
  next: string | undefined
): SearchImagesSuccessAction => ({
  type: SEARCH_IMAGE_SUCCESS,
  searchService,
  results,
  totalImages,
  query,
  next
});

export interface SearchImagesFailureAction {
  type: typeof SEARCH_IMAGE_FAILURE;
  searchService: SearchService;
  error: Error;
}

export const searchImagesFailure = (searchService: SearchService, error: Error): SearchImagesFailureAction => ({
  type: SEARCH_IMAGE_FAILURE,
  searchService,
  error
});

export interface UploadImageFilesAction {
  type: typeof UPLOAD_IMAGE_FILES;
  scribeId: number;
  files: FileList;
  uploadMethod: string;
}

export type UploadImageFilesProperties = Omit<UploadImageFilesAction, 'type'>;

export const uploadImageFiles = ({
  scribeId,
  files,
  uploadMethod
}: UploadImageFilesProperties): UploadImageFilesAction => ({
  type: UPLOAD_IMAGE_FILES,
  scribeId,
  files,
  uploadMethod
});

export interface UploadLibraryImageAssetAction {
  type: typeof UPLOAD_LIBRARY_IMAGE_ASSET;
  file: File;
  filename: string;
  scribeId: number;
  originalAssetId: number;
  source?: string;
  sourceIdentifier?: string;
}

export const uploadLibraryImageAsset = (
  file: File,
  filename: string,
  scribeId: number,
  originalAssetId: number,
  source?: string,
  sourceIdentifier?: string
): UploadLibraryImageAssetAction => ({
  type: UPLOAD_LIBRARY_IMAGE_ASSET,
  file,
  filename,
  scribeId,
  originalAssetId,
  source,
  sourceIdentifier
});

export interface UploadImageAssetOnLoadAction {
  type: typeof UPLOAD_IMAGE_ASSET_ON_LOAD;
  libraryElements: Array<ScribeImageElementModelProps>;
  scribeId: number;
}

export const uploadImageAssetOnLoad = (
  libraryElements: Array<ScribeImageElementModelProps>,
  scribeId: number
): UploadImageAssetOnLoadAction => ({
  type: UPLOAD_IMAGE_ASSET_ON_LOAD,
  libraryElements,
  scribeId
});

export const uploadImageAssetSuccess = (assetId?: string | number, originalAssetId?: string | number) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  assetId,
  originalAssetId
});

export const uploadImageAssetFailed = () => ({
  type: UPLOAD_IMAGE_FAILED
});

export interface UploadUserImageAssetAction {
  type: typeof UPLOAD_USER_IMAGE_ASSET;
  file: File;
  filename: string;
  scribeId: number;
  originalAssetId: number;
}

export const uploadUserImageAsset = (
  file: File,
  filename: string,
  scribeId: number,
  originalAssetId: number
): UploadUserImageAssetAction => ({
  type: UPLOAD_USER_IMAGE_ASSET,
  file,
  filename,
  scribeId,
  originalAssetId
});

export interface LoadUserImagesSliceSuccessAction {
  type: typeof LOAD_USER_IMAGES_SLICE_SUCCESS;
  images: Array<VSCAssetImageData>;
}
export const loadUserImagesSliceSuccess = (images: Array<VSCAssetImageData>): LoadUserImagesSliceSuccessAction => ({
  type: LOAD_USER_IMAGES_SLICE_SUCCESS,
  images
});

export interface LoadMoreUserImagesAction {
  type: typeof LOAD_MORE_USER_IMAGES;
}
export const loadMoreUserImages = (): LoadMoreUserImagesAction => ({ type: LOAD_MORE_USER_IMAGES });

export interface CloseImageLibraryAction {
  type: typeof CLOSE_IMAGE_LIBRARY;
}
export const closeImageLibrary = (): CloseImageLibraryAction => ({
  type: CLOSE_IMAGE_LIBRARY
});

export interface LoadUserAiImagesSliceSuccessAction {
  type: typeof LOAD_USER_AI_IMAGES_SLICE_SUCCESS;
  images: Array<VSCAssetImageData>;
}
export const loadUserAiImagesSliceSuccess = (images: Array<VSCAssetImageData>): LoadUserAiImagesSliceSuccessAction => ({
  type: LOAD_USER_AI_IMAGES_SLICE_SUCCESS,
  images
});

export interface LoadMoreUserAiImagesAction {
  type: typeof LOAD_MORE_USER_AI_IMAGES;
}
export const loadMoreUserAiImages = (): LoadMoreUserAiImagesAction => ({ type: LOAD_MORE_USER_AI_IMAGES });

export interface fullyVectorizeImageAndReplaceElementAction {
  type: typeof FULLY_VECTORIZE_IMAGE_AND_REPLACE_ELEMENT;
  elementId: string;
  scribeId: number;
}
export const fullyVectorizeImageAndReplaceElement = (
  elementId: string,
  scribeId: number
): fullyVectorizeImageAndReplaceElementAction => ({
  type: FULLY_VECTORIZE_IMAGE_AND_REPLACE_ELEMENT,
  elementId,
  scribeId
});
