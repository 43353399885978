import {
  ADD_CAMERA,
  AddCameraAction,
  SET_CAMERA_EASE_TYPE,
  SET_CAMERA_TO_MATCH_EDGE,
  SET_CAMERA_TO_MATCH_VIEW,
  SET_VIEW_TO_CAMERA,
  SET_VIEW_TO_ELEMENT,
  SetCameraEasingTypeAction,
  SetCameraToMatchEdgeAction,
  SetCameraToMatchViewAction,
  SetViewToCameraAction,
  SetViewToElementAction
} from 'js/actionCreators/cameraActions';
import { updateScribe } from 'js/actionCreators/scribeActions';
import { CAMERA_EASING_NONE } from 'js/config/consts';
import { getEditorCanvas } from 'js/editor/EditorCanvas/EditorCanvas';
import { getAdjustedNewCameraRect } from 'js/editor/EditorCanvas/helpers/getNewCameraRect';
import ScribeElementModel from 'js/models/ScribeElementModel';
import { allCamerasPinned } from 'js/shared/allCamerasPinned';
import getNewCamera from 'js/shared/helpers/getNewCamera';
import { ExistingScribeModel, ScribeCameraElement } from 'js/types';
import cloneDeep from 'lodash.clonedeep';
import { PutEffect, SelectEffect, call, put, select, takeEvery } from 'redux-saga/effects';

import { getViewportData } from './sagaHelpers/getViewportData';
import { getActiveElements, getScribeById } from './selectors';

function* setCameraToMatchEdge({ edge, position, value, elementIds, scribeId }: SetCameraToMatchEdgeAction) {
  const project: ExistingScribeModel | undefined = yield select(getScribeById, scribeId);
  if (!project) return;
  const clonedProject = cloneDeep(project);
  const targetElements = clonedProject.elements.filter(
    element => elementIds.includes(element.id) && element.type === 'Camera'
  );
  targetElements.forEach(element => {
    if (element.type === 'Camera') {
      const canvasSize = getViewportData(clonedProject.canvasSize);
      if (position) {
        element.x = position.x;
        element.y = position.y;
      }
      element.scale = edge === 'x' ? canvasSize.width / value : canvasSize.height / value;
    }
  });

  yield put(updateScribe(clonedProject));
}

function applyViewToCamera(cameraElement: ScribeCameraElement, viewportData: { width: number; height: number }) {
  const editorCanvas = getEditorCanvas();
  if (editorCanvas && cameraElement) {
    editorCanvas.setViewToCamera(cameraElement, viewportData);
  }
}

function* setCameraEaseType({ easeType, scribeId, elementIds }: SetCameraEasingTypeAction) {
  const project: ExistingScribeModel | undefined = yield select(getScribeById, scribeId);
  if (!project || !elementIds) return;

  const clonedProject = cloneDeep(project);

  const targetElements = clonedProject.elements.filter(
    element => elementIds.includes(element.id) && element.type === 'Camera'
  );
  targetElements.forEach(element => {
    const cameraElement = element as ScribeCameraElement;
    cameraElement.easingType = easeType;
    if (easeType === CAMERA_EASING_NONE) cameraElement.animationTime = 0;
    else if (easeType !== undefined && cameraElement.animationTime === 0) cameraElement.animationTime = 1;
  });
  yield put(updateScribe(clonedProject));
}

function* setViewToCamera({ cameraElement, projectId }: SetViewToCameraAction) {
  const project: ExistingScribeModel | undefined = yield select(getScribeById, projectId);
  const viewportData = getViewportData(project?.canvasSize);
  yield call(applyViewToCamera, cameraElement, viewportData);
}

function applyViewToElement(element: ScribeElementModel) {
  const editorCanvas = getEditorCanvas();
  if (editorCanvas && element) {
    editorCanvas.setViewToActiveObject();
  }
}

function* setViewToElement({ element }: SetViewToElementAction) {
  yield call(applyViewToElement, element);
}

function* addCamera({ projectId }: AddCameraAction) {
  const project: ExistingScribeModel | undefined = yield select(getScribeById, projectId);
  if (!project) return;

  const clonedProject = cloneDeep(project);
  const activeScene = clonedProject.scenes.find(scene => scene.active);
  if (!activeScene) return;
  const areAllPinned = allCamerasPinned(clonedProject);
  const selectedElementIds: Array<string> = yield select(getActiveElements);

  const cameraProps = getNewCamera();
  cameraProps.cameraPinned = areAllPinned;
  const newCameraProps = {
    ...cameraProps,

    animationTime: 1
  };

  const editorCanvas = getEditorCanvas();

  if (editorCanvas) {
    const viewportRectAndCenter = editorCanvas.getViewportRectAndCenter();

    if (viewportRectAndCenter) {
      const newCameraBounds = getAdjustedNewCameraRect(
        viewportRectAndCenter,
        editorCanvas.canvasSize,
        editorCanvas.canvasBounds
      );

      newCameraProps.scale = newCameraBounds.scale;
      newCameraProps.x = newCameraBounds.adjustedCameraRectangle.x;
      newCameraProps.y = newCameraBounds.adjustedCameraRectangle.y;
    }
  }

  let lowestValidIndex = selectedElementIds.reduce((lowest, id) => {
    const elementIndex = activeScene.elementIds.findIndex(elId => elId === id);
    if (elementIndex === -1) return lowest;

    return Math.min(lowest, elementIndex);
  }, activeScene.elementIds.length - 1);

  if (selectedElementIds.length === 1 && lowestValidIndex > 0) {
    const selectedElement = project.elements.find(el => el.id === activeScene.elementIds[lowestValidIndex]);
    if (selectedElement && selectedElement.type === 'Camera') lowestValidIndex++;
    activeScene.elementIds.splice(lowestValidIndex, 0, newCameraProps.id);
  } else if (selectedElementIds.length > 1) {
    const highestValidIndex = selectedElementIds.reduce((highest, id) => {
      const elementIndex = activeScene.elementIds.findIndex(elId => elId === id);
      if (elementIndex === -1) return highest;

      return Math.max(highest, elementIndex);
    }, 0);
    activeScene.elementIds.splice(highestValidIndex + 1, 0, newCameraProps.id);
  } else if (lowestValidIndex === 0) {
    // If the start camera is selected then we place the new camera after the start camera
    activeScene.elementIds.splice(1, 0, newCameraProps.id);
  } else {
    activeScene.elementIds.push(newCameraProps.id);
  }

  clonedProject.elements.push(newCameraProps);

  yield put(updateScribe(clonedProject, false, [newCameraProps.id]));
}

function* setCameraToMatchView({
  cameraId,
  projectId
}: SetCameraToMatchViewAction): Generator<SelectEffect | PutEffect, void, ExistingScribeModel | undefined> {
  const project = yield select(getScribeById, projectId);
  if (!project) return;

  const clonedProject = cloneDeep(project);
  const cameraElement = clonedProject.elements.find((el): el is ScribeCameraElement => el.id === cameraId);
  if (!cameraElement) return;

  const editorCanvas = getEditorCanvas();
  if (!editorCanvas) return;

  const viewportRectAndCenter = editorCanvas.getViewportRectAndCenter();
  if (!viewportRectAndCenter) return;

  const { adjustedCameraRectangle, scale } = getAdjustedNewCameraRect(
    viewportRectAndCenter,
    editorCanvas.canvasSize,
    editorCanvas.canvasBounds
  );

  cameraElement.scale = scale;
  cameraElement.x = adjustedCameraRectangle.x;
  cameraElement.y = adjustedCameraRectangle.y;

  yield put(updateScribe(clonedProject));
}

export default function* cameraSagas() {
  yield takeEvery(ADD_CAMERA, addCamera);
  yield takeEvery(SET_CAMERA_TO_MATCH_EDGE, setCameraToMatchEdge);
  yield takeEvery(SET_VIEW_TO_CAMERA, setViewToCamera);
  yield takeEvery(SET_CAMERA_EASE_TYPE, setCameraEaseType);
  yield takeEvery(SET_VIEW_TO_ELEMENT, setViewToElement);
  yield takeEvery(SET_CAMERA_TO_MATCH_VIEW, setCameraToMatchView);
}
