import { CHANGE_IMAGE_DRAWER_TAB, ChangeImageDrawerTabAction } from 'js/actionCreators/ImageDrawerAction';
import { ImageDrawerState, ImageDrawerTab } from 'js/types';

type ImageDrawerActionTypes = ChangeImageDrawerTabAction;

export const initialState: ImageDrawerState = {
  panelTab: ImageDrawerTab.IMAGE_LIBRARY
};

export default function imageDrawerReducer(state = initialState, action: ImageDrawerActionTypes): ImageDrawerState {
  switch (action.type) {
    case CHANGE_IMAGE_DRAWER_TAB: {
      return {
        ...state,
        panelTab: action.tab
      };
    }

    default:
      return state;
  }
}
