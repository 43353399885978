import uuidv4 from 'uuid/v4';
import { DEFAULT_HAND_CURSOR_ID } from 'js/config/consts';
import {
  AudioClip,
  AudioClipData,
  CanvasSize,
  ScribeCursorId,
  ScribeModelProps,
  ScribeObject,
  ScribeScene,
  ScribeSettings,
  ScribeSettingsProps,
  ScribeSource,
  VSElementModel
} from 'js/types';
import getNewCamera from 'js/shared/helpers/getNewCamera';

import { canvasSizeDefault } from '../shared/resources/scribedefaults';
import {
  pauseTime as defaultPauseTime,
  animationTime as defaultAnimationTime,
  emphasisAnimationTime as defaultEmphasisAnimationTime,
  exitAnimationTime as defaultExitAnimationTime,
  emphasisAnimationLoops as defaultEmphasisAnimationLoops
} from '../shared/resources/scribedefaults';
import { defaultCanvasColor, defaultBackgroundType, defaultTextStylingConfig } from '../config/defaults';
import config from '../config/config';
import blankThumbnailImage from '../../imgs/projthumb-blank.svg';

import ScribeTextElementModel from './ScribeTextElementModel';
import ScribeImageElementModel from './ScribeImageElementModel';
import ScribeShapeElementModel from './ScribeShapeElementModel';
import { applyGradientBackgroundMigration } from './migrationHelpers';
import { ScribeAudioLayerModel } from './ScribeAudioLayerModel';

export const getSettingsWithDefaults = (settings: ScribeSettingsProps = {}): ScribeSettings => ({
  pauseTime: settings.pauseTime ?? defaultPauseTime,
  animationTime: settings.animationTime ?? defaultAnimationTime,
  emphasisAnimationTime: settings.emphasisAnimationTime ?? defaultEmphasisAnimationTime,
  exitAnimationTime: settings.exitAnimationTime ?? defaultExitAnimationTime,
  backgroundColor: settings.backgroundColor || settings.backgroundColour || defaultCanvasColor,
  emphasisAnimationLoops: settings.emphasisAnimationLoops ?? defaultEmphasisAnimationLoops,
  sceneTransitionType: settings.sceneTransitionType,
  sceneTransitionConfig: settings.sceneTransitionConfig,
  sceneTransitionTime: settings.sceneTransitionTime || 0,
  textStylingConfig: settings.textStylingConfig ?? defaultTextStylingConfig
});

class ScribeModel {
  id?: number;
  title: string;
  elements: Array<VSElementModel>;
  canvasSize: CanvasSize;
  thumbnailImage?: string;
  createdOn?: Date;
  updatedOn?: Date;
  settings: ScribeSettings;
  cursor: ScribeCursorId;
  source?: ScribeSource;
  scenes: Array<ScribeScene>;
  audioClips?: Array<AudioClip>;
  audioLayers: Array<ScribeAudioLayerModel>;
  viewOnly?: boolean;
  lastSavedVersion?: string | number;
  loadingId?: string;
  publicSharingId?: string;
  pinAllCameras?: boolean;
  projectAudioLayerIds?: Array<string>;

  constructor({
    id,
    title,
    canvasSize,
    thumbnailImage,
    createdOn,
    updatedOn,
    rawElements,
    settings,
    audio, // Existing scribes with audio object will be migrated to scribes with audioClips array
    audioClips,
    audioLayers,
    cursor = DEFAULT_HAND_CURSOR_ID,
    source,
    scenes,
    publicSharingId,
    pinAllCameras,
    projectAudioLayerIds
  }: ScribeModelProps) {
    const now = new Date();
    this.id = id;
    this.title = title;
    this.canvasSize = canvasSize || canvasSizeDefault;
    this.thumbnailImage = thumbnailImage !== blankThumbnailImage ? thumbnailImage : undefined;
    this.createdOn = createdOn || now;
    this.updatedOn = updatedOn || now;
    this.settings = getSettingsWithDefaults(settings);
    this.cursor = cursor;
    this.elements = [];
    this.source = source;
    this.publicSharingId = publicSharingId;
    this.pinAllCameras = pinAllCameras ?? false;
    this.audioLayers = [];
    this.projectAudioLayerIds = projectAudioLayerIds;

    rawElements?.forEach(rawElement => {
      if (rawElement.type === 'Text') {
        //added migration from older template
        if (!!rawElement.scaleX && rawElement.scaleX !== 1 && !!rawElement.fontSize) {
          rawElement.fontSize = Math.round(rawElement.fontSize * rawElement.scaleX);
          rawElement.scaleX = rawElement.scaleY = 1;
        }
        this.elements.push(new ScribeTextElementModel(rawElement));
      }

      if (rawElement.type === 'Image') {
        this.elements.push(new ScribeImageElementModel(rawElement));
      }

      if (rawElement.type === 'Shape') {
        this.elements.push(new ScribeShapeElementModel(rawElement));
      }

      if (rawElement.type === 'Camera') {
        this.elements.push({
          ...rawElement,
          ...(this.pinAllCameras && { cameraPinned: true })
        });
      }
    });

    // Migrate scribe to Scenes
    if (!scenes) {
      const shouldMigrateGradientBackgroundToStartingElement =
        settings?.backgroundType && settings.backgroundType !== defaultBackgroundType;
      const elementIds = this.elements.map(el => el.id);
      const newSettings = getSettingsWithDefaults(settings);

      if (shouldMigrateGradientBackgroundToStartingElement) {
        applyGradientBackgroundMigration({
          canvasSize: canvasSize ?? 'landscape',
          originalSceneSettings: settings,
          newSceneSettings: newSettings,
          elementsArray: this.elements,
          elementIds
        });
      }

      this.scenes = [
        {
          id: uuidv4(),
          elementIds,
          settings: newSettings,
          active: true,
          audioLayerIds: [],
          // Only migrate Scribe thumbnail images if they are not the default
          thumbnailImage:
            typeof thumbnailImage === 'string' &&
            (thumbnailImage.includes('base64') ||
              (config.CONTENTFUL_SPACE_ID && thumbnailImage.includes(config.CONTENTFUL_SPACE_ID)))
              ? thumbnailImage
              : undefined
        }
      ];
    } else {
      const newScenes: Array<ScribeScene> = [];
      scenes.forEach(scene => {
        const shouldMigrateGradientBackgroundToStartingElement =
          scene.settings.backgroundType && scene.settings.backgroundType !== defaultBackgroundType;
        const elementIds = scene.elementIds;
        const newSettings = getSettingsWithDefaults(scene.settings);

        if (shouldMigrateGradientBackgroundToStartingElement) {
          applyGradientBackgroundMigration({
            canvasSize: canvasSize ?? 'landscape',
            originalSceneSettings: scene.settings,
            newSceneSettings: newSettings,
            elementsArray: this.elements,
            elementIds
          });
        }

        newScenes.push({
          ...scene,
          settings: newSettings,
          elementIds
        });
      });

      this.scenes = newScenes;
    }

    // Insert Initial Cameras if required
    this.scenes.forEach(scene => {
      const matchingRawElement = rawElements?.find(rawElement => rawElement.id === scene.elementIds[0]);
      if (!matchingRawElement || (matchingRawElement && matchingRawElement.type !== 'Camera')) {
        const newCamera = getNewCamera({ cameraPinned: this.pinAllCameras });
        newCamera.animationTime = 0;
        this.elements.push(newCamera);
        scene.elementIds.unshift(newCamera.id);
      }
    });

    // Migrate audio to audioClips array
    if (!audioClips && audio) {
      this.audioClips = [
        {
          id: audio.id ?? uuidv4(),
          assetId: audio.assetId,
          source: audio.source,
          volume: audio.volume ?? 1,
          fadeOutDurationSeconds: 0,
          filename: 'Audio file',
          startTime: 0,
          startOffset: 0,
          duration: audio.duration,
          instanceDuration: audio.duration,
          type: audio.id === undefined ? 'project' : audio.type
        }
      ];
    } else if (!audioClips || !Array.isArray(audioClips)) {
      this.audioClips = [];
    } else {
      this.audioClips = audioClips
        .map((clip: AudioClipData) => {
          const toReturn = { ...clip, id: clip.id ?? uuidv4() };
          if (clip.type === 'background' || clip.type === 'voiceover' || clip.type === undefined) {
            toReturn.type = 'project';
          }
          if (clip.fadeOutDurationSeconds === undefined) toReturn.fadeOutDurationSeconds = 0;
          if (clip.startOffset === undefined) toReturn.startOffset = 0;
          if (clip.startTime === undefined) toReturn.startTime = 0;
          if (clip.instanceDuration === undefined) toReturn.instanceDuration = clip.duration;
          return toReturn;
        })
        .filter(clip => !!clip.assetId)
        .map(clip => {
          if (clip.fileName && !clip.filename) {
            clip.filename = clip.fileName;
          }
          return clip;
        });

      this.audioClips.forEach(clip => {
        if (clip.id === undefined) {
          clip.id = uuidv4();
        }
        if (clip.type === 'background' || clip.type === 'voiceover' || clip.type === undefined) {
          clip.type = 'project';
        }
      });
    }
    //finish migrating audioclips
    if (!audioLayers) audioLayers = [];
    const projectClips = this.audioClips.filter(clip => clip.type === 'project');
    const clipsWithoutAudioLayer = projectClips.filter(
      clip => !audioLayers?.some(layer => layer.audioClipIds.includes(clip.id))
    );

    if (clipsWithoutAudioLayer.length > 0 && audioLayers) {
      clipsWithoutAudioLayer.forEach(clip => {
        if (clip.type !== 'scene') {
          const newAudioLayer = new ScribeAudioLayerModel({
            id: uuidv4(),
            audioClipIds: [clip.id]
          });
          audioLayers?.push(newAudioLayer);
          if (!this.projectAudioLayerIds) this.projectAudioLayerIds = [];
          this.projectAudioLayerIds.push(newAudioLayer.id);
        }
      });
    }
    if (audioLayers) {
      audioLayers?.forEach(audioLayer => {
        if (this.audioClips) {
          this.audioLayers.push(new ScribeAudioLayerModel(audioLayer));
        }
      });
    }
  }

  static fromObject(scribeObject: ScribeObject, otherScribeModelConfig?: Partial<ScribeModelProps>) {
    const contents = scribeObject.projectModel;

    const createdOnDate = new Date(scribeObject.createdDate);
    const updatedOnDate = scribeObject.modifiedDate ? new Date(scribeObject.modifiedDate) : createdOnDate;

    const model = new ScribeModel({
      id: scribeObject.projectDataId,
      title: contents.title,
      canvasSize: contents.canvasSize,
      thumbnailImage: contents.thumbnailImage,
      createdOn: createdOnDate,
      updatedOn: updatedOnDate,
      rawElements: contents.elements || [],
      settings: contents.settings,
      audio: contents.audio,
      audioClips: contents.audioClips,
      audioLayers: contents.audioLayers,
      cursor: contents.cursor,
      source: contents.source,
      scenes: contents.scenes,
      publicSharingId: scribeObject.publicSharingId,
      pinAllCameras: contents.pinAllCameras,
      projectAudioLayerIds: contents.projectAudioLayerIds,
      ...(otherScribeModelConfig || {})
    });

    model.lastSavedVersion = scribeObject.version;
    model.viewOnly = scribeObject.viewOnly;
    return model;
  }
}

export default ScribeModel;
