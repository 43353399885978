import React from 'react';
import { GET_PROJECT_SHARING_ID, REQUEST_PREVIEW, setupPlaybackForPreview } from 'js/actionCreators/previewActions';
import { ALLOWED_CURSORS, ALLOWED_DRAG_CURSORS, ALLOWED_ERASE_CURSORS } from 'js/config/consts';
import { sendErrorToSentry } from 'js/logging';
import { loadAllSelectableFonts, loadUserSelectableFonts } from 'js/shared/helpers/preloadScribeFonts';
import cloneDeep from 'lodash.clonedeep';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import getCursorsResources from 'js/sagas/sagaHelpers/getCursorsResources';
import getCursorTimings from 'js/sagas/sagaHelpers/getCursorTimings';
import { getScribeForPlaybackError, getScribeForPlaybackSuccess } from 'js/actionCreators/playbackActions';
import { loadCursorsSuccess } from 'js/actionCreators/loadCursorsSuccessAction';
import { updateScribe } from 'js/actionCreators/scribeActions';
import { previewLinkLoading, showError } from 'js/actionCreators/uiActions';
import { RAISE_A_TICKET_URL } from 'js/config/config';

import { appServices } from '../shared/helpers/app-services/AppServices';

import { getViewportData } from './sagaHelpers/getViewportData';
import { cursorAllowedListFilter } from './sagaHelpers/cursorAllowedListFilter';
import { getScribeById } from './selectors';
import { getCachedUrlsForAudioClips } from './sagaHelpers/getCachedUrlsForAudioClips';
import { cacheImagesForPlayback } from './sagaHelpers/cacheImagesForPlayback';

function* loadCursorsForPreview() {
  const cursors = yield call({ fn: appServices.getCursorsForPreview, context: appServices });

  const allowedCursors = cursors.filter(cursor => cursorAllowedListFilter(cursor, ALLOWED_CURSORS));

  const allowedEraseCursors = cursors.filter(cursor => cursorAllowedListFilter(cursor, ALLOWED_ERASE_CURSORS));

  const allowedDragCursors = cursors.filter(cursor => cursorAllowedListFilter(cursor, ALLOWED_DRAG_CURSORS));

  yield put(loadCursorsSuccess({ allowedCursors, allowedEraseCursors, allowedDragCursors }));
}

function* requestPreview({ projectModel, signedUrls }) {
  try {
    // Load assets into cache
    const assetBlobUrls = yield call(
      {
        fn: appServices.addSignedUrlsToCache,
        context: appServices
      },
      signedUrls
    );

    const project = cloneDeep(projectModel);

    const textElementsThatHaveACustomFont = project.elements.filter(el => el.type === 'Text' && el?.font?.assetId);

    if (textElementsThatHaveACustomFont && textElementsThatHaveACustomFont.length > 0) {
      const fontsList = textElementsThatHaveACustomFont.map(el => ({
        fontname: el.font.label,
        fontUrl: assetBlobUrls?.find(blobUrl => blobUrl.assetId === el.font.assetId)?.blobUrl
      }));

      const currentScribeText = textElementsThatHaveACustomFont.reduce((prev, el) => {
        return prev + el.text;
      }, '');

      yield call(loadUserSelectableFonts, currentScribeText, fontsList);
    }

    yield call(loadAllSelectableFonts);

    // Load audio assets
    if (project.audioClips?.length !== 0) {
      project.audioClips = getCachedUrlsForAudioClips(project.audioClips, assetBlobUrls);
    }

    // Load images
    const imageElements = project.elements.filter(el => el.type === 'Image');

    if (imageElements.length) {
      yield cacheImagesForPlayback(imageElements, assetBlobUrls);
    }

    yield loadCursorsForPreview();

    project.cursorData = {
      resourcesAndMetadata: yield call(getCursorsResources, project),
      timings: getCursorTimings(project)
    };

    project.viewport = getViewportData(project.canvasSize);

    yield put(setupPlaybackForPreview());
    yield put(getScribeForPlaybackSuccess(project));
  } catch (error) {
    console.error(error);
    sendErrorToSentry(error);
    yield put(getScribeForPlaybackError(error.message));
  }
}

function* getProjectSharingId({ projectId }) {
  yield put(previewLinkLoading(true));
  const scribe = yield select(state => getScribeById(state, projectId));

  try {
    const { publicSharingId } = yield call(appServices.getProjectSharingId, projectId);

    const newActiveScribe = cloneDeep(scribe);

    newActiveScribe.publicSharingId = publicSharingId;

    yield put(updateScribe(newActiveScribe, true));
  } catch (error) {
    yield put(
      showError({
        message: <span>Oh no, an error has occurred when creating your shareable preview link. Please try again.</span>,
        description: (
          <span>
            If this error persists,
            <a href={RAISE_A_TICKET_URL} className="flash__text-link" target="_blank" rel="noopener noreferrer">
              {' '}
              contact our Support Team.
            </a>
          </span>
        )
      })
    );
    sendErrorToSentry(error);
  }
  yield put(previewLinkLoading(false));
}

function* previewSagas() {
  yield takeLatest(REQUEST_PREVIEW, requestPreview);
  yield takeLatest(GET_PROJECT_SHARING_ID, getProjectSharingId);
}

export default previewSagas;
