import { fabric } from 'fabric';
import { CAMERA_COLOR, EDITOR_CANVAS_SELECTION_OPTIONS } from 'js/config/defaults';
import iconLockImageUrl from 'imgs/icons/IconLock.svg';
import iconPinImageUrl from 'imgs/icons/IconPin.svg';
import iconUnpinImageUrl from 'imgs/icons/IconUnpin.svg';

import CameraElement from '../CameraElement';

import { assertIsActiveSelection } from './canvasElementHelpers';

const CONTROL_SIZE = 24;

/**
 * I hate this, but this is the only way to fix the ActiveSelection "class" used throughout fabric.
 * There is an issue (https://sparkol.atlassian.net/browse/VSP2-3391) where custom controls are printed to
 * the canvas context (ctx) BEFORE the bounds of objects in groups are printed to the context. This monkeypatch
 * simply moves the order of execution so that controls are printed last (this.callSuper('_renderControls', ctx, styleOverride))
 */
const monkeyPatchActiveSelectionPrototype = () => {
  // @ts-ignore _renderControls is inherited from `Group` which is inherited from `Object`.
  // Types are wrong http://fabricjs.com/docs/fabric.ActiveSelection.html#_renderControls
  fabric.ActiveSelection.prototype._renderControls = function(
    ctx: CanvasRenderingContext2D,
    styleOverride: object,
    childrenOverride: {
      hasControls: boolean;
      forActiveSelection: boolean;
    }
  ) {
    ctx.save();

    // @ts-ignore - isMoving is an undocumented and untyped property that is used as state
    // for denoting if a user is moving the selection http://fabricjs.com/docs/fabric.js.html#:~:text=transform.target.isMoving%20%3D%20true%3B
    ctx.globalAlpha = this.isMoving ? this.borderOpacityWhenMoving ?? 1 : 1;

    childrenOverride = childrenOverride || {};
    if (typeof childrenOverride.hasControls === 'undefined') {
      childrenOverride.hasControls = false;
    }
    childrenOverride.forActiveSelection = true;
    for (let i = 0, len = this._objects.length; i < len; i++) {
      // @ts-ignore _renderControls is inherited from from `Object`. types are wrong
      this._objects[i]._renderControls(ctx, childrenOverride);
    }
    // @ts-ignore callSuper is not typed correctly as fabric doesn't actually use JS classes and uses a library called `klass` to create its "class"-like system
    this.callSuper('_renderControls', ctx, styleOverride);
    ctx.restore();
  };
};

const loadIconPromise = (url: string): Promise<fabric.Object> =>
  new Promise(resolve => {
    fabric.loadSVGFromURL(url, (img, options) => resolve(fabric.util.groupSVGElements(img, options)));
  });

const isAllCameras = (fabricObject: fabric.Object): boolean => {
  let isAllCameraObjects = false;
  if (assertIsActiveSelection(fabricObject)) {
    isAllCameraObjects = fabricObject.getObjects().every(fabricObj => fabricObj instanceof CameraElement);
  }

  if (fabricObject instanceof CameraElement) {
    isAllCameraObjects = true;
  }

  return isAllCameraObjects;
};

const isPinnedOrAllPinned = (fabricObject: fabric.Object) => {
  let isPinnedOrAllPin = false;

  if (fabricObject instanceof CameraElement && !!fabricObject.cameraPinned) {
    isPinnedOrAllPin = true;
  }

  if (assertIsActiveSelection(fabricObject)) {
    isPinnedOrAllPin = fabricObject
      .getObjects()
      .every(fabricObj => fabricObj instanceof CameraElement && !!fabricObj.cameraPinned);
  }

  return isPinnedOrAllPin;
};

const initControlsAddons = async () => {
  monkeyPatchActiveSelectionPrototype();

  const BLACK = getComputedStyle(document.documentElement).getPropertyValue('--colorEditorViewBlack');

  const [lockIcon, pinIcon, unpinIcon] = await Promise.all([
    loadIconPromise(iconLockImageUrl),
    loadIconPromise(iconPinImageUrl),
    loadIconPromise(iconUnpinImageUrl)
  ]);

  fabric.Object.prototype.controls.lockIndicator = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: 24,
    cursorStyle: 'default',
    render(ctx, left, top, _styleOverride, fabricObject) {
      let isLockedOrContainLocked;
      const isCamera = fabricObject instanceof CameraElement;

      if (assertIsActiveSelection(fabricObject)) {
        isLockedOrContainLocked = fabricObject.getObjects().some(fabObj => fabObj.lockMovementX);
      }

      if (fabricObject.lockMovementX) {
        isLockedOrContainLocked = true;
      }

      if (!isLockedOrContainLocked) {
        return;
      }

      const button = new fabric.Rect({
        left: left,
        top: top,
        width: CONTROL_SIZE,
        height: CONTROL_SIZE,
        stroke: isCamera ? CAMERA_COLOR : EDITOR_CANVAS_SELECTION_OPTIONS.borderColor,
        fill: BLACK,
        strokeWidth: 1,
        rx: 3,
        ry: 3,
        angle: fabricObject.angle,
        originX: 'center',
        originY: 'center'
      });
      lockIcon.setOptions({
        left: left,
        top: top,
        originX: 'center',
        originY: 'center'
      });
      lockIcon.scaleToWidth(16);
      lockIcon.scaleToHeight(16);

      button.render(ctx);
      lockIcon.render(ctx);
    }
  });

  CameraElement.prototype.controls.pinControl = new fabric.Control({
    x: 0.5,
    y: 0,
    sizeX: CONTROL_SIZE,
    sizeY: CONTROL_SIZE,
    cursorStyleHandler(_eventData, _control, fabricObject): string {
      return isAllCameras(fabricObject) ? 'pointer' : 'default';
    },
    getMouseUpHandler(_eventData, fabricObject) {
      return isAllCameras(fabricObject)
        ? () => {
            const pinned = isPinnedOrAllPinned(fabricObject);

            if (pinned) {
              fabricObject.canvas?.onUnpinActiveCameras();
            } else {
              fabricObject.canvas?.onPinActiveCameras();
            }

            return true;
          }
        : () => false;
    },
    render(ctx, left, top, _styleOverride, fabricObject) {
      if (!isAllCameras(fabricObject)) return;

      const pinned = isPinnedOrAllPinned(fabricObject);

      const button = new fabric.Rect({
        left: left,
        top: top,
        width: CONTROL_SIZE,
        height: CONTROL_SIZE,
        stroke: CAMERA_COLOR,
        fill: BLACK,
        strokeWidth: 1,
        rx: 3,
        ry: 3,
        angle: fabricObject.angle,
        originX: 'center',
        originY: 'center'
      });
      pinIcon.setOptions({
        left: left,
        top: top,
        originX: 'center',
        originY: 'center'
      });

      unpinIcon.setOptions({
        left: left,
        top: top,
        originX: 'center',
        originY: 'center'
      });

      button.render(ctx);

      const icon = pinned ? unpinIcon : pinIcon;

      icon.render(ctx);
    }
  });
};

export default initControlsAddons;
