import { select, takeEvery, SelectEffect } from 'redux-saga/effects';
import { UPGRADE_ACCOUNT } from 'js/actionCreators/userAccountAction';
import { ACCOUNT_TYPES } from 'js/config/consts';

function* redirectToPricingPage(): Generator<SelectEffect, void, string> {
  const currentPath = yield select(state => state.router?.location?.pathname || '');
  const accountType = yield select(state => state.auth?.currentUser?.accountType);
  const redirectURL = `/app${currentPath}`;
  window.localStorage.setItem('checkout-success-redirect', redirectURL);
  const params = new URLSearchParams({
    referrer: 'vsc',
    action: 'upgrade',
    accountType
  });
  window.location.href =
    accountType === ACCOUNT_TYPES.LITE ? `/account/subscriptions?${params.toString()}` : '/pricing/';
}

function* userAccountSagas() {
  yield takeEvery(UPGRADE_ACCOUNT, redirectToPricingPage);
}

export default userAccountSagas;
