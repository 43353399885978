import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import { ACCOUNT_TYPES, APP_DATA_KEYS } from 'js/config/consts';
import { appServices } from 'js/shared/helpers/app-services/AppServices';
import { AUTH_SUCCESS } from 'js/actionCreators/authActions';
import { trackHitDownloadLimit, UpgradeSubscriptionClickedEventTrigger } from 'js/actionCreators/trackingActions';
import { upgradeAccount } from 'js/actionCreators/userAccountAction';
import { useTypedSelector } from 'js/selectors/typedUseSelector';

import {
  DOWNLOAD_REQUEST,
  STOP_RENDER_STATUS_POLLING,
  downloadRequestSuccess,
  downloadRequestFailed,
  getVideoDownloadUrlSuccess,
  getVideoDownloadUrlFailed,
  GET_VIDEO_DOWNLOAD_URL_SUCCESS,
  updateFreeDownloadCount,
  updateDownloadCountFailed,
  START_RENDER_STATUS_POLLING,
  RENDER_FAILED,
  GET_VIDEO_DOWNLOAD_URL_FAILED,
  abortDownload,
  ABORT_DOWNLOAD,
  ABORT_DOWNLOAD_USER_ACTION,
  updateLiteDownloadDetails,
  DOWNLOAD_SUCCESS_UPSELL_ACTION
} from '../actionCreators/downloadActions';
import { sendErrorToSentry } from '../logging';
import getScribeLengthMs from '../playback/lib/Playback/helpers/getScribeLengthMs';

import { getProjectById, getUserAccountType } from './selectors';
import getDownloadTrackingProperties from './sagaHelpers/getDownloadTrackingProperties';
import projectIsStillUploadingAssets from './sagaHelpers/projectIsStillUploadingAssets';
import { createDownloadAssetsFromProject } from './sagaHelpers/createDownloadAssetsFromProject';

function* requestDownload(action) {
  const state = yield select();

  const project = getProjectById(state, action.scribeId);

  if (!project) return;

  const userId = state.auth.currentUser?.id;

  if (!userId) return;

  const trackingProperties = yield getDownloadTrackingProperties(project, action.format, state.templates);

  try {
    if (projectIsStillUploadingAssets(project)) {
      throw new Error('PROJECT_IS_STILL_UPLOADING_IMAGES');
    }

    const scribeLength = getScribeLengthMs(project) / 1000;

    let assets = [];

    assets = createDownloadAssetsFromProject(project);

    const result = yield call(appServices.renderCloud, {
      scribeId: action.scribeId,
      format: action.format,
      assets,
      videoDuration: scribeLength
    });
    // will rename to requestId throughout app
    const { requestId: shortId, numSegments } = result;
    yield put(
      downloadRequestSuccess({
        numSegments,
        shortId,
        format: action.format,
        trackingProperties,
        scribeId: action.scribeId
      })
    );
  } catch (e) {
    sendErrorToSentry(e);
    yield put(downloadRequestFailed(e, trackingProperties, action.scribeId));
  }
}

function* requestVideoDownloadUrl(action) {
  try {
    const result = yield call(appServices.getVideoDownloadUrl, action.shortId, action.format);
    yield put(
      getVideoDownloadUrlSuccess({
        url: result,
        trackingProperties: action.trackingProperties,
        format: action.format,
        scribeId: action.scribeId
      })
    );
  } catch (e) {
    sendErrorToSentry(e);
    yield put(getVideoDownloadUrlFailed(e, action.trackingProperties, action.scribeId));
  }
}

function* logSuccessfulDownloadCount(action) {
  try {
    const userAccountType = yield select(getUserAccountType);
    if (userAccountType === ACCOUNT_TYPES.FREE) {
      const currentDownloadCount = yield select(state => state.download?.freeDownloadCount || 0);
      const { freeDownloadCount } = yield appServices.setAppData(APP_DATA_KEYS.FREE_DOWNLOAD_COUNT, {
        freeDownloadCount: currentDownloadCount + 1
      });
      yield put(updateFreeDownloadCount(freeDownloadCount));
    } else {
      const { downloadsRemaining } = yield fetchAndStoreDownloadDetails(userAccountType);
      if (downloadsRemaining === 0) {
        yield put(trackHitDownloadLimit(action.scribeId));
      }
    }
  } catch (error) {
    yield put(updateDownloadCountFailed());
  }
}

function* fetchSuccessfulDownloadCount() {
  try {
    const userAccountType = yield select(getUserAccountType);
    if (userAccountType === ACCOUNT_TYPES.FREE) {
      const { freeDownloadCount } = yield appServices.getAppData(APP_DATA_KEYS.FREE_DOWNLOAD_COUNT);
      yield put(updateFreeDownloadCount(freeDownloadCount));
    } else {
      yield fetchAndStoreDownloadDetails(userAccountType);
    }
  } catch (error) {
    yield put(updateDownloadCountFailed());
  }
}

function* listenToDownloadPollingStarted({ shortId, trackingProperties }) {
  const result = yield take([
    ABORT_DOWNLOAD_USER_ACTION,
    GET_VIDEO_DOWNLOAD_URL_SUCCESS,
    RENDER_FAILED,
    GET_VIDEO_DOWNLOAD_URL_FAILED
  ]);

  if (result.type === ABORT_DOWNLOAD_USER_ACTION) {
    yield put(abortDownload(shortId, trackingProperties));
  }
}

function* handleAbortedDownload({ shortId }) {
  try {
    yield call(appServices.abortRenderCloud, shortId);
  } catch (error) {
    console.error('Request to abort download failed', error);
  }
}

function* triggerDownloadSuccessUpsellAction() {
  yield put(upgradeAccount(UpgradeSubscriptionClickedEventTrigger.DOWNLOAD_SUCCESS_BANNER));
}

function* downloadSagas() {
  yield takeEvery(DOWNLOAD_REQUEST, requestDownload);
  yield takeEvery(STOP_RENDER_STATUS_POLLING, requestVideoDownloadUrl);
  yield takeEvery(GET_VIDEO_DOWNLOAD_URL_SUCCESS, logSuccessfulDownloadCount);
  yield takeEvery(AUTH_SUCCESS, fetchSuccessfulDownloadCount);
  yield takeEvery(START_RENDER_STATUS_POLLING, listenToDownloadPollingStarted);
  yield takeEvery(ABORT_DOWNLOAD, handleAbortedDownload);
  yield takeEvery(DOWNLOAD_SUCCESS_UPSELL_ACTION, triggerDownloadSuccessUpsellAction);
}

function* fetchAndStoreDownloadDetails(accountType) {
  let response;
  if (accountType === ACCOUNT_TYPES.LITE) {
    try {
      response = yield appServices.getDownloads();
    } catch (error) {
      const message = error.message;
      if (typeof message === 'string' && message.includes('Status: 5')) {
        const downloadsRemaining = useTypedSelector(state => state.download.liteDownloadsRemaining);
        const downloadResetDate = useTypedSelector(state => state.download.downloadResetDate);
        const downloadLimit = useTypedSelector(state => state.download.liteDownloadLimit);

        yield put(updateLiteDownloadDetails(downloadsRemaining - 1, downloadResetDate, downloadLimit));

        return { downloadsRemaining: downloadsRemaining - 1, downloadResetDate, downloadLimit };
      }
    }
    const { downloadsRemaining, downloadResetDate, downloadLimit } = response;

    yield put(updateLiteDownloadDetails(downloadsRemaining, downloadResetDate, downloadLimit));
    return { downloadsRemaining, downloadResetDate, downloadLimit };
  }

  return {};
}

export default downloadSagas;
