import { AccountType, AccountContentKey } from 'js/types';

export enum ACCOUNT_NEXT_ACTION_VALUES {
  PURCHASE = 'Purchase',
  UPGRADE = 'Upgrade'
}

export const accountTypeContent: Record<AccountType, Partial<Record<AccountContentKey, string>>> = {
  FREE: {
    [AccountContentKey.ACCOUNT_NEXT_ACTION]: ACCOUNT_NEXT_ACTION_VALUES.PURCHASE,
    [AccountContentKey.NOUN_PROJECT_WARNING_MSG]: `To search the Noun Project's library of 5 million images you need a Plus or Pro plan. Purchase now to access this feature and more. \n\n During your free trial you can browse and use the 750,000 Noun Project images in the category folders to the left.`,
    [AccountContentKey.DURATION_LIMITS_PANEL_TOOLTIP]: `Purchase a Plus or Pro plan to increase your project duration limit`,
    [AccountContentKey.CUSTOM_FONT_WARNING_MSG]: `Personalize your project by uploading custom fonts and ensure your videos are always on brand. Purchase a Plus or Pro subscription to access this feature and more.`,
    [AccountContentKey.PREVIEW_LINK_WARNING_MSG]: `Share a preview of your video with as many people as you like with a preview link. They don't need a VideoScribe account to view it. Purchase a Plus or Pro subscription to access this feature and more.`,
    [AccountContentKey.DOWNLOAD_UPSELL_MSG]: 'Remove the watermark from your download by purchasing a subscription.'
  },
  LITE: {
    [AccountContentKey.ACCOUNT_NEXT_ACTION]: ACCOUNT_NEXT_ACTION_VALUES.UPGRADE,
    [AccountContentKey.NOUN_PROJECT_WARNING_MSG]: `To search the Noun Project's library of 5 million images you need a Plus or Pro plan. Upgrade to access this feature and more. \n\n With a Lite plan you can browse and use the 750,000 Noun Project images in the category folders to the left.`,
    [AccountContentKey.DURATION_LIMITS_PANEL_TOOLTIP]: `Upgrade to a Plus or Pro plan to increase your project duration limit`,
    [AccountContentKey.CUSTOM_FONT_WARNING_MSG]: `Personalize your project by uploading custom fonts and ensure your videos are always on brand. Upgrade to a Plus or Pro plan to access this feature and more.`,
    [AccountContentKey.PREVIEW_LINK_WARNING_MSG]: `Share a preview of your video with as many people as you like with a preview link. They don't need a VideoScribe account to view it. Upgrade a Plus or Pro subscription to access this feature and more.`,
    [AccountContentKey.DOWNLOAD_UPSELL_MSG]:
      'Upgrade to a Plus or Pro plan now to get unlimited downloads for your projects.'
  },
  PLUS: {},
  PRO: {},
  EDU: {}
};

const mapAccountTypeContentKeyToContent = (accountType: AccountType, contentKey: AccountContentKey) => {
  return accountTypeContent[accountType][contentKey] ?? '';
};

export default mapAccountTypeContentKeyToContent;
