import { SetShowElementModalAction, SET_SHOW_ELEMENT_MODAL } from 'js/actionCreators/scribeActions';
import { ImageState, VSCAssetImageSourceName } from 'js/types';
import cloneDeep from 'lodash.clonedeep';
import { DEFAULT_PAGE_SIZE } from 'js/shared/components/ImageList';

import {
  GET_IMAGE_LIBRARIES_SUCCESS,
  GET_IMAGE_LIBRARY_SUCCESS,
  GET_USER_IMAGES_REQUESTED,
  GET_USER_IMAGES_FAILURE,
  GET_USER_IMAGES_SUCCESS,
  DELETE_USER_IMAGE_SUCCESS,
  SEARCH_IMAGE,
  SEARCH_IMAGE_FAILURE,
  SEARCH_IMAGE_SUCCESS,
  GetImageLibrariesSuccessAction,
  GetImageLibrarySuccessAction,
  GetUserImagesFailureAction,
  GetUserImagesSuccessAction,
  DeleteUserImageSuccessAction,
  SearchImagesAction,
  SearchImagesSuccessAction,
  SearchImagesFailureAction,
  LOAD_USER_IMAGES_SLICE_SUCCESS,
  LOAD_MORE_USER_IMAGES,
  LoadUserImagesSliceSuccessAction,
  LoadMoreUserImagesAction,
  CLOSE_IMAGE_LIBRARY,
  CloseImageLibraryAction,
  SET_SEARCH_MEMO,
  SetSearchMemoAction,
  LOAD_MORE_USER_AI_IMAGES,
  LOAD_USER_AI_IMAGES_SLICE_SUCCESS,
  LoadMoreUserAiImagesAction,
  LoadUserAiImagesSliceSuccessAction,
  GET_IMAGE_LIBRARIES,
  GetImageLibrariesFailureAction,
  GET_IMAGE_LIBRARIES_FAILURE,
  GetImageLibrariesAction
} from '../actionCreators/imagesActions';

const initialState: ImageState = {
  loadingLibraries: false,
  libraries: [],
  nounProjectLibraries: [],
  userImagesLoading: false,
  userImageAssets: [],
  userImages: [],
  search: { results: [] },
  searchNounProject: { results: [] },
  userImagePage: 1,
  userImagePageSize: DEFAULT_PAGE_SIZE,
  userAiImagesLoading: false,
  userAiImageAssets: [],
  userAiImages: [],
  userAiImagePage: 1
};

type ImagesAction =
  | GetImageLibrariesSuccessAction
  | GetImageLibrarySuccessAction
  | { type: typeof GET_USER_IMAGES_REQUESTED }
  | GetUserImagesFailureAction
  | GetUserImagesSuccessAction
  | DeleteUserImageSuccessAction
  | SearchImagesAction
  | SearchImagesSuccessAction
  | SearchImagesFailureAction
  | SetShowElementModalAction
  | LoadUserImagesSliceSuccessAction
  | LoadMoreUserImagesAction
  | CloseImageLibraryAction
  | SetSearchMemoAction
  | LoadUserAiImagesSliceSuccessAction
  | LoadMoreUserAiImagesAction
  | GetImageLibrariesAction
  | GetImageLibrariesFailureAction;

export default function imagesReducer(state = initialState, action: ImagesAction): ImageState {
  switch (action.type) {
    case SET_SEARCH_MEMO:
      return {
        ...state,
        searchMemo: action.searchMemo
      };

    case GET_IMAGE_LIBRARIES: {
      return {
        ...state,
        loadingLibraries: true
      };
    }

    case GET_IMAGE_LIBRARIES_SUCCESS:
      return {
        ...state,
        loadingLibraries: false,
        libraries: action.libraries,
        nounProjectLibraries: action.libraries
      };

    case GET_IMAGE_LIBRARIES_FAILURE: {
      return {
        ...state,
        loadingLibraries: false
      };
    }

    case GET_IMAGE_LIBRARY_SUCCESS:
      return {
        ...state,
        libraries:
          action.searchService !== 'videoscribe'
            ? state.libraries
            : state.libraries.map(library => {
                if (library.id !== action.libraryId) return library;
                return {
                  ...library,
                  images: [...(library.images || []), ...action.library],
                  cursor: action.cursor,
                  searchService: action.searchService
                };
              }),
        nounProjectLibraries:
          action.searchService !== 'noun-project'
            ? state.nounProjectLibraries
            : state.nounProjectLibraries.map(library => {
                if (library.id !== action.libraryId) return library;
                return {
                  ...library,
                  images: [...(library.images || []), ...action.library],
                  cursor: action.cursor,
                  searchService: action.searchService
                };
              })
      };

    case GET_USER_IMAGES_REQUESTED: {
      return {
        ...state,
        userImagesLoading: true,
        userAiImagesLoading: true
      };
    }

    case GET_USER_IMAGES_FAILURE: {
      return {
        ...state,
        userImagesLoading: false,
        userAiImagesLoading: false
      };
    }

    case GET_USER_IMAGES_SUCCESS: {
      return {
        ...state,
        userImagesLoading: !!action.payload.length,
        userAiImagesLoading: false,
        userImageAssets: action.payload.filter(
          asset => !asset.sourceName || asset.sourceName !== VSCAssetImageSourceName.AI
        ),
        userAiImageAssets: action.payload.filter(asset => asset.sourceName === VSCAssetImageSourceName.AI)
      };
    }

    case LOAD_USER_IMAGES_SLICE_SUCCESS: {
      return {
        ...state,
        userImagesLoading: false,
        userImages: [...state.userImages, ...action.images]
      };
    }

    case LOAD_USER_AI_IMAGES_SLICE_SUCCESS: {
      return {
        ...state,
        userImagesLoading: false,
        userAiImages: [...state.userAiImages, ...action.images]
      };
    }

    case LOAD_MORE_USER_IMAGES: {
      return {
        ...state,
        userImagesLoading: true,
        userImagePage: state.userImagePage + 1
      };
    }

    case LOAD_MORE_USER_AI_IMAGES: {
      return {
        ...state,
        userAiImagesLoading: true,
        userAiImagePage: state.userImagePage + 1
      };
    }

    case CLOSE_IMAGE_LIBRARY: {
      return {
        ...state,
        userImagePage: 1,
        userImages: [],
        userAiImages: []
      };
    }

    case DELETE_USER_IMAGE_SUCCESS: {
      const userImages = state.userImages.filter(img => img.id !== action.imageId);
      const userAiImages = state.userAiImages.filter(img => img.id !== action.imageId);
      return {
        ...state,
        userImages,
        userAiImages
      };
    }

    case SEARCH_IMAGE: {
      if (!action.offset) {
        return {
          ...state,
          search: {
            ...state.search,
            query: action.searchService === 'videoscribe' ? action.query : state.search.query,
            results: action.query === state.search.query ? state.search.results : null
          },
          searchNounProject: {
            ...state.searchNounProject,
            query: action.searchService === 'noun-project' ? action.query : state.searchNounProject.query,
            results: action.query === state.searchNounProject.query ? state.searchNounProject.results : null
          }
        };
      }
      return {
        ...state,
        search: {
          ...state.search
        }
      };
    }

    case SEARCH_IMAGE_SUCCESS: {
      const searchResultKey = action.searchService === 'videoscribe' ? 'search' : 'searchNounProject';
      return {
        ...state,
        [searchResultKey]: {
          ...state[searchResultKey],
          results: [...(state[searchResultKey].results || []), ...action.results],
          totalImages: action.totalImages,
          query: action.query,
          next: action.next
        }
      };
    }

    case SEARCH_IMAGE_FAILURE:
      if (action.searchService === 'videoscribe') {
        return {
          ...state,
          search: {
            ...state.search
          }
        };
      } else {
        return {
          ...state,
          searchNounProject: {
            ...state.search
          }
        };
      }

    case SET_SHOW_ELEMENT_MODAL: {
      if (action.replaceElement) {
        const { libraries: originalLibraries } = state;
        const libraries = cloneDeep(originalLibraries);
        libraries.forEach(lib => {
          delete lib.cursor;
        });
        return {
          ...state,
          libraries
        };
      }
      return state;
    }

    default:
      return state;
  }
}
