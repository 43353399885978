import * as PIXI from 'pixi.js';
import chunk from 'lodash.chunk';
import slugify from 'slugify';
import { MaskPathAndBrushData } from 'js/types';
import { findSeparateImageShapes } from 'js/playback/lib/Playback/helpers/findSeparateImageShapes';
import { debugTextSource } from 'js/playback/lib/Playback/helpers/debugTextSource';

import { getMaskPath, storeMaskPath } from '../lib/LocalDatabase';

import { thin } from './thinning';
import { generateMaskPath } from './text-draw';
import { getMemoryCache } from './generateCharacterMaskPathCache';
import { renderCharacterPixels } from './renderCharacterPixels';

const BITMAP_PREFIX = 'bitmap-';

const renderer = new PIXI.Renderer({
  width: 500,
  height: 500,
  backgroundAlpha: 0,
  preserveDrawingBuffer: true,
  clearBeforeRender: true
});

export const generateCharacterMaskPath = async (
  singleChar: PIXI.Text | PIXI.BitmapText,
  lineHeight: number,
  isTextRTL: boolean,
  fontName: string,
  fontWeight: string,
  fontStyle: string,
  align: string,
  fontSize: number,
  xOffset = 0,
  yOffset = 0
): Promise<Array<MaskPathAndBrushData>> => {
  const isBitmapText = singleChar instanceof PIXI.BitmapText;
  const bitmapPrefix = isBitmapText ? BITMAP_PREFIX : '';
  const cacheIdentifier = `${bitmapPrefix}${slugify(
    fontName
  )}-${fontWeight}-${fontStyle}-${align}-${fontSize}-${lineHeight.toFixed(2)}-${singleChar.text}`;

  if (!isBitmapText) {
    const memoryCached = getMemoryCache().get(cacheIdentifier);
    if (memoryCached) return memoryCached;

    const cached = await getMaskPath(cacheIdentifier);
    if (cached) {
      getMemoryCache().set(cacheIdentifier, cached.data);

      return cached.data;
    }
  }

  if (import.meta.env.DEV && import.meta.env.VITE_DEBUG_TEXT_SOURCE) {
    debugTextSource(renderer, singleChar);
  }

  const charWidth = Math.round(singleChar.width);
  const charHeight = Math.round(singleChar.height);

  const rgba: Uint8Array = await renderCharacterPixels(renderer, singleChar);
  let binaryPixels = chunk(rgba, 4).map(pixel => (pixel.some(color => color > 0) ? 1 : 0));

  // yOffset is how many rows to add or remove from the top
  if (yOffset > 0) {
    const yOffsetPixels = new Array(yOffset * charWidth).fill(0);
    binaryPixels = yOffsetPixels.concat(binaryPixels);
  } else if (yOffset < 0) {
    binaryPixels = binaryPixels.slice(Math.abs(yOffset) * charWidth + 1);
  }

  const pixelsByRow: (1 | 0)[][] = chunk(binaryPixels, charWidth);
  if (!pixelsByRow.length) return [];

  if (xOffset) {
    const iterations = Math.abs(xOffset);
    for (let i = 0; i < iterations; i++) {
      if (xOffset > 0) {
        // positive offset, pad 0s to the left
        for (let row = 0; row < pixelsByRow.length; row++) {
          pixelsByRow[row].unshift(0);
        }
      } else {
        // negative offset, move left and pad 0s to the right
        for (let row = 0; row < pixelsByRow.length; row++) {
          const pix = pixelsByRow[row].shift();
          if (pix === undefined) continue;
          pixelsByRow[row].push(0);
        }
      }
    }
  }

  const shapes = findSeparateImageShapes({ data: pixelsByRow });
  const output = shapes.map(shape => {
    const flatPixels = shape.data.flat();
    const [thinned, iterations] = thin(
      flatPixels,
      charWidth + (xOffset > 0 ? Math.abs(xOffset) : 0),
      charHeight + yOffset
    );
    const path = generateMaskPath(thinned, isTextRTL).flat() || [];

    const output = {
      maskPath: path,
      brushSize: iterations
    };
    return output;
  });

  if (!isBitmapText) {
    await storeMaskPath(cacheIdentifier, output);
    getMemoryCache().set(cacheIdentifier, output);
  }

  return output;
};
