import { TagOption } from 'js/shared/components/Tags';
import { UseTagsStatePath } from 'js/shared/hooks/useTags';
import {
  RatingOption,
  ExistingScribeModel,
  AddAIGenerationTrigger,
  BetaAiPanelTab,
  BetaAiPanelStep,
  ImageGenerationInputs,
  ScriptGenerationInputs,
  VoiceoverGenerationInputs
} from 'js/types';

export const CHANGE_AI_PANEL_TAB = 'CHANGE_AI_PANEL_TAB';
export const CHANGE_AI_PANEL_STEP = 'CHANGE_AI_PANEL_STEP';
export const GENERATE_IMAGES = 'GENERATE_IMAGES';
export const REGENERATE_IMAGES = 'REGENERATE_IMAGES';
export const RESET_IMAGE_GENERATION = 'RESET_IMAGE_GENERATION';
export const IMAGE_GENERATION_SUCCESS = 'IMAGE_GENERATION_SUCCESS';
export const IMAGE_GENERATION_FAILED = 'IMAGE_GENERATION_FAILED';
export const SUBMIT_IMAGES_FEEDBACK = 'SUBMIT_IMAGES_FEEDBACK';
export const SET_IMAGE_GENERATION_INPUTS = 'SET_IMAGE_GENERATION_INPUTS';
export const ADD_GENERATED_IMAGE_TO_PROJECT = 'ADD_GENERATED_IMAGE_TO_PROJECT';
export const FETCH_GENERATION_COUNT_SUCCESS = 'FETCH_GENERATION_COUNT_SUCCESS';
export const FETCH_VOICEOVER_SECONDS_GENERATED_SUCCESS = 'FETCH_VOICEOVER_SECONDS_GENERATED_SUCCESS';
export const FETCH_SCRIPT_SECONDS_GENERATED_SUCCESS = 'FETCH_SCRIPT_SECONDS_GENERATED_SUCCESS';
export const RATE_IMAGE_GENERATION_RESULT = 'RATE_IMAGE_GENERATION_RESULT';
export const TRY_NEW_IMAGE_PROMPT = 'TRY_NEW_IMAGE_PROMPT';
export const EDIT_IMAGE_PROMPT = 'EDIT_IMAGE_PROMPT';
export const SET_VOICEOVER_GENERATION_INPUTS = 'SET_VOICEOVER_GENERATION_INPUTS';
export const GENERATE_VOICEOVER = 'GENERATE_VOICEOVER';
export const VOICEOVER_GENERATION_SUCCESS = 'VOICEOVER_GENERATION_SUCCESS';
export const VOICEOVER_GENERATION_FAILED = 'VOICEOVER_GENERATION_FAILED';
export const RESET_VOICEOVER_GENERATION = 'RESET_VOICEOVER_GENERATION';
export const EDIT_VOICEOVER = 'EDIT_VOICEOVER';
export const SUBMIT_VOICEOVER_FEEDBACK = 'SUBMIT_VOICEOVER_FEEDBACK';
export const SET_SCRIPT_GENERATION_INPUTS = 'SET_SCRIPT_GENERATION_INPUTS';
export const GENERATE_SCRIPT = 'GENERATE_SCRIPT';
export const GENERATE_SCRIPT_FAILED = 'GENERATE_SCRIPT_FAILED';
export const GENERATE_SCRIPT_SUCCESS = 'GENERATE_SCRIPT_SUCCESS';
export const SUBMIT_SCRIPT_FEEDBACK = 'SUBMIT_SCRIPT_FEEDBACK';
export const ADD_GENERATED_SCRIPT_TO_PROJECT = 'ADD_GENERATED_SCRIPT_TO_PROJECT';
export const RESET_SCRIPT_GENERATION = 'RESET_SCRIPT_GENERATION';
export const PREVIEW_VOICEOVER_VOICE = 'PREVIEW_VOICEOVER_VOICE';
export const RATE_VOICEOVER_GENERATION_RESULT = 'RATE_VOICEOVER_GENERATION_RESULT';
export const SET_GENERATION_FEEDBACK = 'SET_GENERATION_FEEDBACK';
export const RATE_SCRIPT_GENERATION_RESULT = 'RATE_SCRIPT_GENERATION_RESULT';
export const SAVE_GENERATED_SCRIPT = 'SAVE_GENERATED_SCRIPT';
export const TRY_NEW_SCRIPT_PROMPT = 'TRY_NEW_SCRIPT_PROMPT';
export const COPY_SCRIPT_TO_CLIPBOARD = 'COPY_SCRIPT_TO_CLIPBOARD';
export const SET_TAGS = 'SET_TAGS';
export const SET_FEEDBACK_COMMENTS = 'SET_FEEDBACK_COMMENTS';

export enum ChangeStepTrigger {
  LEFT_HAND_PANEL = 'Left Hand Panel',
  IMAGE_LIBRARY = 'Image Library',
  LEFT_HAND_PANEL_TAB_HEADING = 'Left Hand Panel Tab Heading',
  AUDIO_LEFT_HAND_PANEL = 'Audio Left Hand Panel',
  AUDIO_LIBRARY = 'Audio Library',
  PROJECT_SCRIPT_DRAWER = 'Project Script Drawer'
}

export interface ChangeAiPanelTabAction {
  type: typeof CHANGE_AI_PANEL_TAB;
  tab: BetaAiPanelTab;
  trigger?: ChangeStepTrigger;
}

export const changeAiPanelTab = (tab: BetaAiPanelTab, trigger?: ChangeStepTrigger): ChangeAiPanelTabAction => ({
  type: CHANGE_AI_PANEL_TAB,
  tab,
  trigger
});

export interface ChangeAiPanelStepAction {
  type: typeof CHANGE_AI_PANEL_STEP;
  tab: BetaAiPanelTab;
  step: BetaAiPanelStep;
  trigger?: ChangeStepTrigger;
}

export const changeAiPanelStep = (tab: BetaAiPanelTab, step: BetaAiPanelStep, trigger?: ChangeStepTrigger) => ({
  type: CHANGE_AI_PANEL_STEP,
  tab,
  step,
  trigger
});

export interface GenerateImagesAction {
  type: typeof GENERATE_IMAGES;
}

export const generateImages = (): GenerateImagesAction => ({
  type: GENERATE_IMAGES
});

export interface RegenerateImagesAction {
  type: typeof REGENERATE_IMAGES;
}

export const regenerateImages = (): RegenerateImagesAction => ({
  type: REGENERATE_IMAGES
});

export interface ResetImageGenerationAction {
  type: typeof RESET_IMAGE_GENERATION;
}

export const resetImageGeneration = (): ResetImageGenerationAction => ({
  type: RESET_IMAGE_GENERATION
});

export interface TryNewImagePromptAction {
  type: typeof TRY_NEW_IMAGE_PROMPT;
}

export const tryNewImagePrompt = (): TryNewImagePromptAction => ({
  type: TRY_NEW_IMAGE_PROMPT
});

export interface EditImagePromptAction {
  type: typeof EDIT_IMAGE_PROMPT;
}

export const editImagePrompt = (): EditImagePromptAction => ({
  type: EDIT_IMAGE_PROMPT
});

export interface ImageGenerationSuccessAction {
  type: typeof IMAGE_GENERATION_SUCCESS;
  images: {
    url: string;
    assetId: number;
  }[];
  imageGenerationId: string;
  enhancedPrompt: string;
}

export const imageGenerationSuccess = (
  images: {
    url: string;
    assetId: number;
  }[],
  imageGenerationId: string,
  enhancedPrompt: string
): ImageGenerationSuccessAction => ({
  type: IMAGE_GENERATION_SUCCESS,
  images,
  imageGenerationId,
  enhancedPrompt
});

export interface ImageGenerationFailedAction {
  type: typeof IMAGE_GENERATION_FAILED;
  error: string;
}

export const imageGenerationFailed = (error: string): ImageGenerationFailedAction => ({
  type: IMAGE_GENERATION_FAILED,
  error
});

type ImageFeedbackData = {
  imageId: string;
  rating: RatingOption;
  options: string[];
  comment: string;
};

export interface SubmitImagesFeedbackAction {
  type: typeof SUBMIT_IMAGES_FEEDBACK;
  feedbackData: ImageFeedbackData[];
}

export const submitImagesFeedback = (feedbackData: ImageFeedbackData[]): SubmitImagesFeedbackAction => ({
  type: SUBMIT_IMAGES_FEEDBACK,
  feedbackData
});

export interface SetImageGenerationInputsAction extends Partial<ImageGenerationInputs> {
  type: typeof SET_IMAGE_GENERATION_INPUTS;
}

export const setImageGenerationInputs = (inputs: Partial<ImageGenerationInputs>): SetImageGenerationInputsAction => ({
  type: SET_IMAGE_GENERATION_INPUTS,
  ...inputs
});

export interface AddGeneratedImageToProjectAction {
  type: typeof ADD_GENERATED_IMAGE_TO_PROJECT;
  assetId: number;
  project: ExistingScribeModel;
  rating: RatingOption;
  eventTrigger: AddAIGenerationTrigger;
}

export const addGeneratedImageToProject = (
  assetId: number,
  project: ExistingScribeModel,
  rating: RatingOption,
  eventTrigger: AddAIGenerationTrigger
): AddGeneratedImageToProjectAction => ({
  type: ADD_GENERATED_IMAGE_TO_PROJECT,
  assetId,
  project,
  rating,
  eventTrigger
});

export interface RateImageGenerationResultAction {
  type: typeof RATE_IMAGE_GENERATION_RESULT;
  rating: RatingOption;
  imageId: string;
}

export const rateImageGenerationResult = (rating: RatingOption, imageId: string): RateImageGenerationResultAction => ({
  type: RATE_IMAGE_GENERATION_RESULT,
  rating,
  imageId
});

export interface FetchGenerationCountSuccessAction {
  type: typeof FETCH_GENERATION_COUNT_SUCCESS;
  imageGenerations: number;
}

export const fetchGenerationCountSuccess = (imageGenerations: number): FetchGenerationCountSuccessAction => ({
  type: FETCH_GENERATION_COUNT_SUCCESS,
  imageGenerations
});

export interface SetVoiceoverGenerationInputsAction extends Partial<VoiceoverGenerationInputs> {
  type: typeof SET_VOICEOVER_GENERATION_INPUTS;
}

export const setVoiceoverGenerationInputs = (
  inputs: Partial<VoiceoverGenerationInputs>
): SetVoiceoverGenerationInputsAction => ({
  type: SET_VOICEOVER_GENERATION_INPUTS,
  ...inputs
});

export interface GenerateVoiceoverAction {
  type: typeof GENERATE_VOICEOVER;
}

export const generateVoiceover = (): GenerateVoiceoverAction => ({
  type: GENERATE_VOICEOVER
});

export interface FetchVoiceoverSecondsGeneratedSuccessAction {
  type: typeof FETCH_VOICEOVER_SECONDS_GENERATED_SUCCESS;
  voiceoverSecondsGenerated: number;
}

export const fetchVoiceoverSecondsGeneratedSuccess = (
  voiceoverSecondsGenerated: number
): FetchVoiceoverSecondsGeneratedSuccessAction => ({
  type: FETCH_VOICEOVER_SECONDS_GENERATED_SUCCESS,
  voiceoverSecondsGenerated
});

export interface FetchScriptSecondsGeneratedSuccessAction {
  type: typeof FETCH_SCRIPT_SECONDS_GENERATED_SUCCESS;
  scriptSecondsGenerated: number;
}

export const fetchScriptSecondsGeneratedSuccess = (
  scriptSecondsGenerated: number
): FetchScriptSecondsGeneratedSuccessAction => ({
  type: FETCH_SCRIPT_SECONDS_GENERATED_SUCCESS,
  scriptSecondsGenerated
});

export interface VoiceoverGenerationSuccessAction {
  type: typeof VOICEOVER_GENERATION_SUCCESS;
  voiceoverUrl: string;
  voiceoverId: string;
  voiceoverAssetId: number;
  voiceoverFileDurationMs: number;
}

export const voiceoverGenerationSuccess = (
  voiceoverUrl: string,
  voiceoverId: string,
  voiceoverAssetId: number,
  voiceoverFileDurationMs: number
): VoiceoverGenerationSuccessAction => ({
  type: VOICEOVER_GENERATION_SUCCESS,
  voiceoverUrl,
  voiceoverId,
  voiceoverAssetId,
  voiceoverFileDurationMs
});

export interface VoiceoverGenerationFailedAction {
  type: typeof VOICEOVER_GENERATION_FAILED;
  error: string;
}

export const voiceoverGenerationFailed = (error: string): VoiceoverGenerationFailedAction => ({
  type: VOICEOVER_GENERATION_FAILED,
  error
});

export interface ResetVoiceoverGenerationAction {
  type: typeof RESET_VOICEOVER_GENERATION;
}

export const resetVoiceoverGeneration = (): ResetVoiceoverGenerationAction => ({
  type: RESET_VOICEOVER_GENERATION
});

export interface EditVoiceoverAction {
  type: typeof EDIT_VOICEOVER;
}

export const editVoiceover = (): EditVoiceoverAction => ({
  type: EDIT_VOICEOVER
});

export interface SubmitVoiceoverFeedbackAction {
  type: typeof SUBMIT_VOICEOVER_FEEDBACK;
  rating: RatingOption;
  tags: string[];
  comment: string;
}

export const submitVoiceoverFeedback = (
  rating: RatingOption,
  tags: string[],
  comment: string
): SubmitVoiceoverFeedbackAction => ({
  type: SUBMIT_VOICEOVER_FEEDBACK,
  rating,
  tags,
  comment
});

export interface PreviewVoiceoverVoiceAction {
  type: typeof PREVIEW_VOICEOVER_VOICE;
  language: string;
  voice: string;
  gender: string;
}

export const previewVoiceoverVoice = (
  language: string,
  voice: string,
  gender: string
): PreviewVoiceoverVoiceAction => ({
  type: PREVIEW_VOICEOVER_VOICE,
  language,
  voice,
  gender
});

export interface RateVoiceoverGenerationResultAction {
  type: typeof RATE_VOICEOVER_GENERATION_RESULT;
  rating: RatingOption;
}

export const rateVoiceoverGenerationResult = (rating: RatingOption): RateVoiceoverGenerationResultAction => ({
  type: RATE_VOICEOVER_GENERATION_RESULT,
  rating
});

export interface SetScriptGenerationInputsAction extends Partial<ScriptGenerationInputs> {
  type: typeof SET_SCRIPT_GENERATION_INPUTS;
}

export const setScriptGenerationInputs = (
  inputs: Partial<ScriptGenerationInputs>
): SetScriptGenerationInputsAction => ({
  type: SET_SCRIPT_GENERATION_INPUTS,
  ...inputs
});

export interface GenerateScriptAction {
  type: typeof GENERATE_SCRIPT;
}

export const generateScript = (): GenerateScriptAction => ({
  type: GENERATE_SCRIPT
});

export interface GenerateScriptFailedAction {
  type: typeof GENERATE_SCRIPT_FAILED;
  error: Error;
}

export const generateScriptFailed = (error: Error): GenerateScriptFailedAction => ({
  type: GENERATE_SCRIPT_FAILED,
  error
});

export interface GenerateScriptSuccessAction {
  type: typeof GENERATE_SCRIPT_SUCCESS;
  script: string;
}

export const generateScriptSuccess = (script: string): GenerateScriptSuccessAction => ({
  type: GENERATE_SCRIPT_SUCCESS,
  script
});

export interface SubmitScriptFeedbackAction {
  type: typeof SUBMIT_SCRIPT_FEEDBACK;
  rating: RatingOption;
  tags: string[];
  comment: string;
}

export const submitScriptFeedback = (
  rating: RatingOption,
  tags: string[],
  comment: string
): SubmitScriptFeedbackAction => ({
  type: SUBMIT_SCRIPT_FEEDBACK,
  rating,
  tags,
  comment
});

export interface AddGeneratedScriptToProjectAction {
  type: typeof ADD_GENERATED_SCRIPT_TO_PROJECT;
  script: string;
  project: ExistingScribeModel;
  rating: RatingOption;
  eventTrigger: AddAIGenerationTrigger;
}

export const addGeneratedScriptToProject = (
  script: string,
  project: ExistingScribeModel,
  rating: RatingOption,
  eventTrigger: AddAIGenerationTrigger
): AddGeneratedScriptToProjectAction => ({
  type: ADD_GENERATED_SCRIPT_TO_PROJECT,
  script,
  project,
  rating,
  eventTrigger
});

export interface ResetScriptGenerationAction {
  type: typeof RESET_SCRIPT_GENERATION;
}

export const resetScriptGeneration = (): ResetScriptGenerationAction => ({
  type: RESET_SCRIPT_GENERATION
});

export type FeedbackStateUpdatePath = 'voiceover' | 'script';
export interface SetGenerationFeedbackAction {
  type: typeof SET_GENERATION_FEEDBACK;
  feedback: RatingOption | undefined;
  updatePath: FeedbackStateUpdatePath;
}

export const setGenerationFeedback = (
  feedback: RatingOption | undefined,
  updatePath: FeedbackStateUpdatePath
): SetGenerationFeedbackAction => ({
  type: SET_GENERATION_FEEDBACK,
  feedback,
  updatePath
});

export interface RateScriptGenerationResultAction {
  type: typeof RATE_SCRIPT_GENERATION_RESULT;
  rating: RatingOption;
}

export const rateScriptGenerationResult = (rating: RatingOption): RateScriptGenerationResultAction => ({
  type: RATE_SCRIPT_GENERATION_RESULT,
  rating
});

export interface SaveGeneratedScriptAction {
  type: typeof SAVE_GENERATED_SCRIPT;
  projectId: number;
  script: string;
}

export const saveGeneratedScript = (projectId: number, script: string): SaveGeneratedScriptAction => ({
  type: SAVE_GENERATED_SCRIPT,
  script,
  projectId
});

export interface TryNewScriptPromptAction {
  type: typeof TRY_NEW_SCRIPT_PROMPT;
  reset: boolean;
}

export const tryNewScriptPrompt = (reset: boolean): TryNewScriptPromptAction => ({
  type: TRY_NEW_SCRIPT_PROMPT,
  reset
});

export interface CopyScriptToClipboardAction {
  type: typeof COPY_SCRIPT_TO_CLIPBOARD;
}

export const copyScriptToClipboard = (): CopyScriptToClipboardAction => ({
  type: COPY_SCRIPT_TO_CLIPBOARD
});

export interface SetTagsAction {
  type: typeof SET_TAGS;
  tags: TagOption[];
  statePath: UseTagsStatePath;
}

export const setTags = (tags: TagOption[], statePath: UseTagsStatePath): SetTagsAction => ({
  type: SET_TAGS,
  tags,
  statePath
});

export interface SetFeedbackCommentsAction {
  type: typeof SET_FEEDBACK_COMMENTS;
  comments: string;
  updatePath: FeedbackStateUpdatePath;
}

export const setFeedbackComments = (
  comments: string,
  updatePath: FeedbackStateUpdatePath
): SetFeedbackCommentsAction => ({
  type: SET_FEEDBACK_COMMENTS,
  comments,
  updatePath
});
