import { all } from 'redux-saga/effects';

import authSagas from './authSagas';
import scribeSagas from './scribeSagas';
import imagesSagas from './imagesSagas';
import uiSagas from './uiSagas';
import routerSagas from './routerSagas';
import downloadSagas from './downloadSagas';
import downloadManagerSagas from './downloadManagerSagas';
import pollDownloadSagas, { startRenderPollingSagas } from './pollDownloadSagas';
import checkoutSagas from './checkoutSagas';
import monitoringSaga from './monitoringSaga';
import templatesSaga from './templatesSaga';
import marketingSagas from './marketingSagas';
import playbackSagas from './playbackSagas';
import editorSagas from './editorSagas';
import mixpanelSagas from './mixpanel';
import userSettings from './userSettingsSagas';
import userpilotSagas from './userpilot';
import croppingSagas from './croppingSagas';
import cursorSagas from './cursorSagas';
import elementSagas from './elementSagas';
import hotjarSagas from './hotjar';
import imageUploadSagas from './imageUploadSagas';
import appStartupSagas from './appStartupSagas';
import hubspotSagas from './hubspot';
import trialSagas from './trialSagas';
import animationSagas from './animationSagas';
import sceneTransitionsSagas from './sceneTransitionsSagas';
import fontSagas from './fontSagas';
import audioSagas from './audioSagas';
import previewSagas from './previewSagas';
import cameraSagas from './cameraSagas';
import localDatabaseSagas from './localDatabaseSagas';
import imageProcessingSagas from './imageProcessingSagas';
import autoSaveSagas from './autoSaveSaga';
import gpuSagas from './gpuSagas';
import acceptedTermsSaga from './acceptedTermsSaga';
import acceptedMarketingSaga from './acceptedMarketingSaga';
import userAccountSagas from './userAccountSagas';
import broadcastChannelSaga from './broadcastChannelSaga';
import createProjectAssetFromLibrarySaga from './createProjectAssetFromLibrarySaga';
import betaAiFeaturesSagas from './betaAiFeaturesSagas';
import urlToFileSagas from './urlToFileSagas';
import projectScriptsSagas from './projectScriptsSagas';
export default function* sagas() {
  yield all([
    broadcastChannelSaga(),
    autoSaveSagas(),
    monitoringSaga(),
    mixpanelSagas(),
    authSagas(),
    appStartupSagas(),
    scribeSagas(),
    imagesSagas(),
    uiSagas(),
    routerSagas(),
    pollDownloadSagas(),
    startRenderPollingSagas(),
    checkoutSagas(),
    downloadSagas(),
    downloadManagerSagas(),
    templatesSaga(),
    marketingSagas(),
    editorSagas(),
    playbackSagas(),
    userSettings(),
    userpilotSagas(),
    croppingSagas(),
    cursorSagas(),
    elementSagas(),
    hotjarSagas(),
    imageUploadSagas(),
    hubspotSagas(),
    trialSagas(),
    animationSagas(),
    sceneTransitionsSagas(),
    fontSagas(),
    audioSagas(),
    previewSagas(),
    cameraSagas(),
    localDatabaseSagas(),
    imageProcessingSagas(),
    gpuSagas(),
    acceptedTermsSaga(),
    acceptedMarketingSaga(),
    userAccountSagas(),
    createProjectAssetFromLibrarySaga(),
    betaAiFeaturesSagas(),
    urlToFileSagas(),
    projectScriptsSagas()
  ]);
}
