import { ImageDrawerTab } from 'js/types';

export const CHANGE_IMAGE_DRAWER_TAB = 'CHANGE_IMAGE_DRAWER_TAB';

export interface ChangeImageDrawerTabAction {
  type: typeof CHANGE_IMAGE_DRAWER_TAB;
  tab: ImageDrawerTab;
}

export const changeImageDrawerTab = (tab: ImageDrawerTab): ChangeImageDrawerTabAction => ({
  type: CHANGE_IMAGE_DRAWER_TAB,
  tab
});
