import { UIState, LeftHandPanel } from 'js/types';
import { getProjectViewModeInitialState } from 'js/sagas/sagaHelpers/projectViewMode';
import { getTimelineOpenStatus } from 'js/sagas/sagaHelpers/timelineLocalStorage';
import {
  NEEDS_TO_ACCEPT_TERMS,
  NEEDS_TO_ACCEPT_TERMS_ERROR,
  NeedsToAcceptTermsAction,
  NeedsToAcceptTermsErrorAction,
  USER_ACCEPTS_TERMS,
  USER_ACCEPTS_TERMS_FAILED,
  USER_ACCEPTS_TERMS_SUCCESS,
  UserAcceptsTermsAction,
  UserAcceptsTermsFailedAction,
  UserAcceptsTermsSuccessAction
} from 'js/actionCreators/acceptedTermsActions';
import {
  VALIDATE_TOKEN_FAILED,
  SET_TRIAL_HAS_EXPIRED,
  SET_NO_ONLINE_STORAGE,
  LOGOUT
} from 'js/actionCreators/authActions';

import {
  SHOW_CALLOUT,
  HIDE_CALLOUT,
  SHOW_ERROR,
  EDITOR_MODES,
  UNLOAD_PLAYBACK,
  LOAD_PLAYBACK,
  LOADED_PLAYBACK_ASSETS,
  UI_LOADING,
  CURRENT_HOME_VIEW,
  AUDIO_RECORDING_START,
  AUDIO_RECORDING_RESET,
  SHOW_RECORD_PANEL,
  CURRENT_EDIT_ANIMATION_TAB,
  APPLICATION_FONTS_LOADED,
  SET_EDITOR_LOADING_MESSAGE,
  ShowCalloutAction,
  ShowErrorAction,
  UnloadPlaybackAction,
  LoadPlaybackAction,
  SetCurrentHomeViewAction,
  AudioRecordingStartAction,
  AudioRecordingCancelAction,
  SetCurrentEditAnimationTabAction,
  SetEditorLoadingMessageAction,
  StartLoadingAction,
  StopLoadingAction,
  PreviewLinkLoadingAction,
  SetProjectsViewModeAction,
  PREVIEW_LINK_LOADING,
  SET_PROJECTS_VIEW_MODE,
  ShowElementsPanelAction,
  SHOW_ELEMENTS_PANEL,
  SHOW_PASTE_WARNING,
  ShowPasteWarningAction,
  CLOSE_PASTE_WARNING,
  ClosePasteWarningAction,
  SHOW_ELEMENT_NOT_IN_VIEW_WARNING,
  ShowElementNotInViewWarningAction,
  CloseElementNotInViewWarningAction,
  CLOSE_ELEMENT_NOT_IN_VIEW_WARNING,
  SHOW_UPGRADE_WARNING_MODAL,
  ShowUpgradeWarningModalAction,
  HIDE_UPGRADE_WARNING_MODAL,
  HideUpgradeModalAction,
  SET_SPLIT_MODE,
  SetSplitModeAction,
  SHOW_PREMIUM_FEATURE_MODAL,
  ShowPremiumFeatureModalAction,
  CLOSE_PREMIUM_FEATURE_MODAL,
  ClosePremiumFeatureModalAction,
  DismissAudioLimitsModalAction,
  ShowAudioLimitsModalAction,
  DISMISS_AUDIO_LIMITS_MODAL,
  SHOW_AUDIO_LIMITS_MODAL,
  SHOW_LEFT_HAND_PANEL,
  ShowLeftHandPanelAction,
  CLOSE_LEFT_HAND_PANEL,
  CloseLeftHandPanelAction
} from '../actionCreators/uiActions';
import { RESET_STATE } from '../actionCreators/generalActions';
import {
  EditorSetEditPanelModeAction,
  EDITOR_SET_EDIT_PANEL_MODE,
  TOGGLE_NEW_TIMELINE,
  EDITOR_CLOSED,
  TOGGLE_FIRST_ARROW_PRESS
} from '../actionCreators/editorActions';
import {
  PrepScribeForEditorSuccessAction,
  PREP_SCRIBE_FOR_EDITOR_SUCCESS,
  ToggleGridAction,
  TOGGLE_GRID,
  UPDATE_EDITOR_POSITION_ZOOM,
  UpdateEditorPositionAndZoomAction,
  TOGGLE_CANVAS_DRAG,
  ToggleCanvasDragAction,
  SET_SELECTED_AUDIO_CLIP,
  SetSelectedAudioClipAction
} from '../actionCreators/scribeActions';
import {
  LOAD_IMAGE_CROP,
  CANCEL_IMAGE_CROP,
  CONFIRM_IMAGE_CROP,
  START_IMAGE_CROP,
  CONFIRM_IMAGE_CROP_SUCCESS,
  CONFIRM_IMAGE_CROP_FAILED,
  StartImageCropAction,
  LoadImageCropAction,
  ConfirmImageCropFailedAction
} from '../actionCreators/croppingActions';
import {
  SaveAudioAction,
  SaveAudioFailedAction,
  SaveAudioSuccessAction,
  SAVE_AUDIO,
  SAVE_AUDIO_FAILED,
  SAVE_AUDIO_SUCCESS
} from '../actionCreators/audioActions';

const initialState: UIState = {
  showCallout: null,
  editorMode: EDITOR_MODES.default,
  isLoadingPlayback: false,
  uiIsLoading: false,
  currentHomeView: 'my-projects',
  editPanelMode: null,
  isSavingAudio: {},
  isAudioPanelBusy: false,
  currentEditAnimationTab: null,
  applicationFontsLoaded: false,
  editorLoadingMessage: 'Loading...',
  previewLinkIsLoading: false,
  projectsViewMode: getProjectViewModeInitialState(),
  newTimeline: getTimelineOpenStatus(),
  showElementsPanel: true,
  firstArrowPressed: false,
  canvasDragOn: false,
  grid: false,
  showPasteWarning: false,
  isCheckingTermsAcceptance: true,
  needsToAcceptTerms: false,
  acceptingTerms: false,
  showElementNotInViewWarning: false,
  elementNotInViewProps: { link: '', onConfirm: undefined, onCancel: undefined },
  showUpgradeWarningModal: false,
  upgradeModalMessage: '',
  audioSplitElement: null,
  showPremiumFeatureModal: false,
  premiumFeatureModalTitle: null,
  premiumFeatureModalContent: null,
  premiumFeatureTriggerSource: null,
  showAudioLimitModal: false,
  leftHandPanel: LeftHandPanel.SCENES
};

type UIAction =
  | {
      type:
        | typeof HIDE_CALLOUT
        | typeof LOADED_PLAYBACK_ASSETS
        | typeof SHOW_RECORD_PANEL
        | typeof CANCEL_IMAGE_CROP
        | typeof CONFIRM_IMAGE_CROP
        | typeof CONFIRM_IMAGE_CROP_SUCCESS
        | typeof APPLICATION_FONTS_LOADED
        | typeof RESET_STATE
        | typeof TOGGLE_NEW_TIMELINE
        | typeof TOGGLE_FIRST_ARROW_PRESS
        | typeof EDITOR_CLOSED
        | typeof VALIDATE_TOKEN_FAILED
        | typeof SET_TRIAL_HAS_EXPIRED
        | typeof SET_NO_ONLINE_STORAGE
        | typeof LOGOUT;
    }
  | ShowCalloutAction
  | ShowErrorAction
  | UnloadPlaybackAction
  | LoadPlaybackAction
  | SetCurrentHomeViewAction
  | SaveAudioAction
  | SaveAudioSuccessAction
  | SaveAudioFailedAction
  | AudioRecordingStartAction
  | AudioRecordingCancelAction
  | StartImageCropAction
  | LoadImageCropAction
  | ConfirmImageCropFailedAction
  | SetCurrentEditAnimationTabAction
  | SetEditorLoadingMessageAction
  | PrepScribeForEditorSuccessAction
  | EditorSetEditPanelModeAction
  | StartLoadingAction
  | StopLoadingAction
  | PreviewLinkLoadingAction
  | SetProjectsViewModeAction
  | ShowElementsPanelAction
  | ToggleGridAction
  | ToggleCanvasDragAction
  | UpdateEditorPositionAndZoomAction
  | ShowPasteWarningAction
  | ClosePasteWarningAction
  | NeedsToAcceptTermsAction
  | UserAcceptsTermsAction
  | UserAcceptsTermsFailedAction
  | UserAcceptsTermsSuccessAction
  | NeedsToAcceptTermsErrorAction
  | ShowElementNotInViewWarningAction
  | CloseElementNotInViewWarningAction
  | ShowUpgradeWarningModalAction
  | HideUpgradeModalAction
  | SetSplitModeAction
  | ShowPremiumFeatureModalAction
  | ClosePremiumFeatureModalAction
  | ShowAudioLimitsModalAction
  | DismissAudioLimitsModalAction
  | SetSelectedAudioClipAction
  | ShowLeftHandPanelAction
  | CloseLeftHandPanelAction;

export default function uiReducers(state = initialState, action: UIAction): UIState {
  switch (action.type) {
    case SHOW_CALLOUT: {
      return {
        ...state,
        showCallout: action.callout
      };
    }

    case HIDE_CALLOUT: {
      return {
        ...state,
        showCallout: null
      };
    }

    case SHOW_ERROR: {
      return {
        ...state,
        showCallout: { title: action.error.message, details: action.error.description, isError: true }
      };
    }

    case RESET_STATE: {
      return {
        ...initialState
      };
    }

    case UNLOAD_PLAYBACK: {
      return {
        ...state,
        isLoadingPlayback: false
      };
    }

    case LOAD_PLAYBACK: {
      return {
        ...state,
        isLoadingPlayback: action.showLoading
      };
    }

    case LOADED_PLAYBACK_ASSETS: {
      return {
        ...state,
        isLoadingPlayback: false
      };
    }

    case UI_LOADING: {
      return {
        ...state,
        uiIsLoading: action.isLoading
      };
    }

    case CURRENT_HOME_VIEW: {
      return {
        ...state,
        currentHomeView: action.currentHomeView
      };
    }

    case EDITOR_SET_EDIT_PANEL_MODE: {
      return {
        ...state,
        editPanelMode: action.mode
      };
    }

    case SAVE_AUDIO: {
      return {
        ...state,
        isSavingAudio: {
          ...state.isSavingAudio,
          [action.audioType]: true
        },
        isAudioPanelBusy: true
      };
    }

    case SAVE_AUDIO_SUCCESS:
    case SAVE_AUDIO_FAILED: {
      return {
        ...state,
        isSavingAudio: {
          ...state.isSavingAudio,
          [action.audioType]: false
        },
        isAudioPanelBusy: false
      };
    }
    case UPDATE_EDITOR_POSITION_ZOOM: {
      let editorTransform = { ...state.editorTransform };
      if (!editorTransform) {
        editorTransform = { [action.sceneId]: action.vpTransform };
      } else {
        editorTransform[action.sceneId] = action.vpTransform;
      }

      return { ...state, editorTransform };
    }

    case SHOW_RECORD_PANEL:
    case AUDIO_RECORDING_START: {
      return {
        ...state,
        isAudioPanelBusy: true
      };
    }

    case AUDIO_RECORDING_RESET: {
      return {
        ...state,
        isAudioPanelBusy: false
      };
    }

    case START_IMAGE_CROP: {
      return {
        ...state,
        uiIsLoading: true
      };
    }

    case LOAD_IMAGE_CROP: {
      return {
        ...state,
        editorMode: EDITOR_MODES.IMAGE_CROP,
        uiIsLoading: false
      };
    }

    case CANCEL_IMAGE_CROP: {
      return {
        ...state,
        editorMode: EDITOR_MODES.default,
        uiIsLoading: false
      };
    }

    case CONFIRM_IMAGE_CROP: {
      return {
        ...state,
        uiIsLoading: true
      };
    }

    case CONFIRM_IMAGE_CROP_FAILED:
    case CONFIRM_IMAGE_CROP_SUCCESS: {
      return {
        ...state,
        editorMode: EDITOR_MODES.default,
        uiIsLoading: false
      };
    }

    case CURRENT_EDIT_ANIMATION_TAB: {
      return {
        ...state,
        currentEditAnimationTab: action.currentEditAnimationTab
      };
    }

    case APPLICATION_FONTS_LOADED: {
      return {
        ...state,
        applicationFontsLoaded: true
      };
    }

    case SET_EDITOR_LOADING_MESSAGE: {
      return {
        ...state,
        editorLoadingMessage: `${action.message}...`
      };
    }

    case PREP_SCRIBE_FOR_EDITOR_SUCCESS: {
      return {
        ...state,
        editorLoadingMessage: initialState.editorLoadingMessage
      };
    }

    case PREVIEW_LINK_LOADING: {
      return {
        ...state,
        previewLinkIsLoading: action.isLoading
      };
    }

    case SET_PROJECTS_VIEW_MODE: {
      return {
        ...state,
        projectsViewMode: action.mode
      };
    }

    case TOGGLE_NEW_TIMELINE: {
      return { ...state, newTimeline: !state.newTimeline };
    }

    case TOGGLE_FIRST_ARROW_PRESS: {
      return { ...state, firstArrowPressed: !state.firstArrowPressed };
    }

    case SHOW_ELEMENTS_PANEL: {
      return {
        ...state,
        showElementsPanel: action.value
      };
    }

    case EDITOR_CLOSED: {
      return {
        ...state,
        showElementsPanel: initialState.showElementsPanel,
        leftHandPanel: initialState.leftHandPanel
      };
    }

    case TOGGLE_GRID: {
      return {
        ...state,
        grid: !state.grid
      };
    }

    case TOGGLE_CANVAS_DRAG: {
      return {
        ...state,
        canvasDragOn: !state.canvasDragOn
      };
    }

    case SHOW_PASTE_WARNING: {
      return {
        ...state,
        showPasteWarning: true
      };
    }

    case CLOSE_PASTE_WARNING: {
      return {
        ...state,
        showPasteWarning: false
      };
    }

    case SHOW_ELEMENT_NOT_IN_VIEW_WARNING: {
      return {
        ...state,
        showElementNotInViewWarning: true,
        elementNotInViewProps: { link: action.link, onConfirm: action.onConfirm, onCancel: action.onCancel }
      };
    }
    case CLOSE_ELEMENT_NOT_IN_VIEW_WARNING: {
      return {
        ...state,
        showElementNotInViewWarning: false
      };
    }

    case NEEDS_TO_ACCEPT_TERMS: {
      return {
        ...state,
        needsToAcceptTerms: action.needsToAccept,
        isCheckingTermsAcceptance: false
      };
    }

    case VALIDATE_TOKEN_FAILED:
    case SET_TRIAL_HAS_EXPIRED:
    case SET_NO_ONLINE_STORAGE:
    case LOGOUT: {
      return {
        ...state,
        isCheckingTermsAcceptance: false
      };
    }

    case NEEDS_TO_ACCEPT_TERMS_ERROR: {
      return {
        ...state,
        isCheckingTermsAcceptance: false,
        needsToAcceptTerms: false
      };
    }

    case USER_ACCEPTS_TERMS: {
      return {
        ...state,
        acceptingTerms: true
      };
    }

    case USER_ACCEPTS_TERMS_FAILED: {
      return {
        ...state,
        acceptingTerms: false
      };
    }

    case USER_ACCEPTS_TERMS_SUCCESS: {
      return {
        ...state,
        needsToAcceptTerms: false
      };
    }

    case SHOW_UPGRADE_WARNING_MODAL: {
      return {
        ...state,
        showUpgradeWarningModal: true,
        upgradeModalMessage: action.message
      };
    }

    case HIDE_UPGRADE_WARNING_MODAL: {
      return {
        ...state,
        showUpgradeWarningModal: false,
        upgradeModalMessage: ''
      };
    }
    case SET_SPLIT_MODE: {
      return {
        ...state,
        newTimeline: true,
        audioSplitElement: action.modeOn === true ? action.id : null
      };
    }

    case SHOW_PREMIUM_FEATURE_MODAL: {
      return {
        ...state,
        showPremiumFeatureModal: true,
        premiumFeatureModalTitle: action.title,
        premiumFeatureModalContent: action.content,
        premiumFeatureTriggerSource: action.triggerSource
      };
    }

    case CLOSE_PREMIUM_FEATURE_MODAL: {
      return {
        ...state,
        showPremiumFeatureModal: false,
        premiumFeatureModalTitle: null,
        premiumFeatureModalContent: null,
        premiumFeatureTriggerSource: null
      };
    }

    case SHOW_AUDIO_LIMITS_MODAL: {
      return {
        ...state,
        showAudioLimitModal: [action.modalType, action.projectId]
      };
    }

    case DISMISS_AUDIO_LIMITS_MODAL: {
      return {
        ...state,
        showAudioLimitModal: false
      };
    }

    case SET_SELECTED_AUDIO_CLIP: {
      return {
        ...state,
        audioSplitElement: null
      };
    }

    case SHOW_LEFT_HAND_PANEL: {
      return {
        ...state,
        leftHandPanel: action.name
      };
    }

    case CLOSE_LEFT_HAND_PANEL: {
      return {
        ...state,
        leftHandPanel: null
      };
    }

    default:
      return state;
  }
}
