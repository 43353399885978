import * as PIXI from 'pixi.js';
import { ENTRANCE_TWEEN_DOCUMENT_KEY } from 'js/config/consts';

import generateScribblePathArray from '../helpers/generateScribblePathArray';
import applyElementPositions from '../helpers/applyElementPositions';
import setupBasicMask from '../helpers/setupBasicMask';
import { MASK_LINE_COLOR } from '../../../../config/defaults';

import AnimatableElement from './AnimatableElement';

export function GifController({ timings, imageResource }) {
  const { gifData, gifTextures: textures } = imageResource.metadata;
  const sprite = new PIXI.AnimatedSprite(textures);

  return {
    sprite,
    update: currentTime => {
      if (currentTime > timings.endTimeMs) return;

      if (currentTime < timings.startTimeMs) {
        sprite.gotoAndStop(0);
        return;
      }

      const elapsedTime = currentTime - timings.startTimeMs;
      const timeIntoLoop = elapsedTime < gifData.gifLengthMs ? elapsedTime : elapsedTime % gifData.gifLengthMs;
      const frameToDisplayInThisTick = gifData.frameTimes.findIndex(frameTime => {
        return frameTime.startTimeMs <= timeIntoLoop && timeIntoLoop < frameTime.endTimeMs;
      });

      if (frameToDisplayInThisTick !== -1) {
        sprite.gotoAndStop(frameToDisplayInThisTick);
      }
    }
  };
}

class VSRasterImage extends AnimatableElement {
  constructor({
    element,
    imageResource,
    animationTime,
    emphasisAnimationTime,
    emphasisAnimationLoops,
    exitAnimationTime,
    pauseTime,
    timings,
    playerRef,
    isGif = false,
    scene
  }) {
    super({
      playerRef,
      animationTime,
      emphasisAnimationTime,
      emphasisAnimationLoops,
      exitAnimationTime,
      pauseTime,
      element,
      timings,
      scene
    });
    if (!element) {
      throw new Error('No element object supplied to VSRasterImage');
    }

    const { scribbleArray, brushSize } = generateScribblePathArray(element.width, element.height);

    this.scaleHolder = null;
    this.isGif = isGif;
    this.maskPathPoints = scribbleArray;
    this.brushSize = brushSize;

    if (this.isGif) {
      this.gifController = new GifController({
        imageResource,
        timings: {
          startTimeMs: timings.startTimeMs + animationTime,
          endTimeMs: timings.scribeTotalLengthMs
        }
      });
    }

    const sprite = this.isGif ? this.gifController.sprite : new PIXI.Sprite(imageResource.texture);
    sprite.alpha = element.opacity ?? 1;

    this.sprite = sprite;

    this.maskLineStyleConfig = {
      width: this.brushSize,
      color: MASK_LINE_COLOR,
      cap: PIXI.LINE_CAP.ROUND,
      join: PIXI.LINE_JOIN.ROUND
    };
    this.mask = setupBasicMask(this.maskLineStyleConfig, this.maskPathPoints[0]);
    this.stageElement = this.createStageElement(element, sprite, this.mask, this.cursor);
    this.scaleHolder = this.stageElement.getChildAt(0);

    if (element[ENTRANCE_TWEEN_DOCUMENT_KEY]) {
      this.clearMasksFromStage();

      if (!this.hasExitMask) {
        this.stageElement.mask = null;
      }

      this.clearCursorFromCanvas();
    }

    this.animationTime = animationTime;
    this.pauseTime = pauseTime;
  }

  update(currentTime, sceneTransitionTime, sceneStartTime) {
    super.update(currentTime, sceneTransitionTime, sceneStartTime);
    this.gifController?.update(currentTime);
  }

  createStageElement = (element, sprite, mask, cursor) => {
    const stageElement = applyElementPositions(this.setUpNestedClips(sprite), element, this.scaleHolder);

    this.scaleHolder.mask = mask;
    this.scaleHolder.addChild(mask, cursor);
    stageElement.name = element.id;

    return stageElement;
  };

  fillRevealMasks = () => {
    this.scaleHolder.mask = null;
    this.mask.visible = false;
  };

  revealAnimation(percentProgress) {
    const shouldRevealWhole = !this.shouldAnimateReveal || percentProgress >= 1;
    if (shouldRevealWhole) {
      this.fillRevealMasks();
      return;
    }

    if (percentProgress < 1) {
      this.scaleHolder.mask = this.mask;
      this.mask.visible = true;
    }

    const hasGoneBackInTime = this.lastPercentProgress > percentProgress;
    if (hasGoneBackInTime) {
      this.mask.clear();
      this.mask.lineStyle(this.maskLineStyleConfig);
      this.lastDrawTo = 0;
    }

    const length = this.maskPathPoints.length;
    const drawTo = Math.floor(length * percentProgress);
    const drawPathSegment = this.maskPathPoints.slice(this.lastDrawTo, drawTo);

    if (drawPathSegment.length) {
      this.mask.moveTo(drawPathSegment[0].x, drawPathSegment[0].y);
      // Only draw the segment from the last update to this update
      drawPathSegment.forEach(point => {
        this.mask.lineTo(point.x, point.y);
      });

      // Update cursor position to the last point in the segment
      const lastPoint = drawPathSegment[drawPathSegment.length - 1];
      this.cursor.x = (lastPoint && lastPoint.x) || 0;
      this.cursor.y = (lastPoint && lastPoint.y) || 0;
    }

    this.lastPercentProgress = percentProgress;
    this.lastDrawTo = drawTo;
  }

  clearMasksFromStage() {
    super.clearMasksFromStage();
    this.fillRevealMasks();
  }
}

export default VSRasterImage;
