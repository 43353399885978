import * as PIXI from 'pixi.js';
import { frameShape, CubicBezier, Point, Arc, QuadraticBezier } from 'wilderness-dom-node';
import { offset } from 'points';
import { Bezier } from 'bezier-js';
import arcToBezier from 'svg-arc-to-cubic-bezier';
import { ENTRANCE_TWEEN_DOCUMENT_KEY } from 'js/config/consts';
import ScribeImageElementModel from 'js/models/ScribeImageElementModel';
import { VSCSVGLoaderResource } from 'js/types';

import applyElementPositions from '../helpers/applyElementPositions';
import { getCurrentStrokeAnimationValues } from '../helpers/getStrokeAnimationValues';
import { getDistanceBetweenPoints, getPointAlongHypAtPercentage } from '../../../../shared/helpers/trig';
import {
  SVG_DEFAULT_STROKE_WIDTH,
  STROKE_ANIMATION_SEGMENT_PERCENTAGE,
  MASK_LINE_COLOR
} from '../../../../config/defaults';
import { shouldRevealAnimation } from '../helpers/drawAnimationType';
import { generateFallbackRevealPath } from '../helpers/generateFallbackRevealPath';

import AnimatableElement from './AnimatableElement';
import VSScene from './VSScene';

const SVG_CURVE_TYPES = {
  CUBIC: 'cubic',
  QUADRATIC: 'quadratic',
  ARC: 'arc'
};

export const MAX_SVG_TEXTURE_SIZE = 4096;
export const MIN_SVG_TEXTURE_SIZE = 1;

function isArc(point: Point): point is Arc {
  return 'curve' in point && point.curve.type === 'arc';
}

function isQuadraticBezier(point: Point): point is QuadraticBezier {
  return 'curve' in point && point.curve.type === 'quadratic';
}

function isCubicBezier(point: Point): point is CubicBezier {
  return 'curve' in point && point.curve.type === 'cubic';
}

type PointWithPathLength = Point & {
  pathLength: number;
};
// Generate the path points array which will be the instructions for how to draw
export const setupMaskPaths = (
  svgElements: NodeListOf<SVGGeometryElement>,
  minX = 0,
  minY = 0,
  viewboxScaleXFactor = 1,
  viewboxScaleYFactor = 1
) => {
  const maskPaths = [...svgElements]
    .filter(el => typeof el.getTotalLength !== 'undefined')
    .map(stroke => {
      const totalLength = stroke.getTotalLength();
      const drawCommands = frameShape(stroke);

      const points = offset(drawCommands.points, -minX, -minY)
        .map((point, pointsIndex, pointsArray): PointWithPathLength | Array<PointWithPathLength> => {
          // // Adjust points based on SVG viewbox
          point.x = point.x * viewboxScaleXFactor;
          point.y = point.y * viewboxScaleYFactor;

          // // Adjust curve points based on SVG viewbox
          if (point.moveTo) {
            return { ...point, pathLength: 0 };
          } else if (isCubicBezier(point)) {
            point.curve.x1 = point.curve.x1 * viewboxScaleXFactor;
            point.curve.x2 = point.curve.x2 * viewboxScaleXFactor;
            point.curve.y1 = point.curve.y1 * viewboxScaleYFactor;
            point.curve.y2 = point.curve.y2 * viewboxScaleYFactor;

            const bezier = new Bezier(
              pointsArray[pointsIndex - 1].x,
              pointsArray[pointsIndex - 1].y,
              point.curve.x1,
              point.curve.y1,
              point.curve.x2,
              point.curve.y2,
              point.x,
              point.y
            );

            return { ...point, pathLength: bezier.length() };
          } else if (isQuadraticBezier(point)) {
            const bezier = new Bezier(
              pointsArray[pointsIndex - 1].x,
              pointsArray[pointsIndex - 1].y,
              point.curve.x1 * viewboxScaleXFactor,
              point.curve.y1 * viewboxScaleYFactor,
              point.x * viewboxScaleXFactor,
              point.y * viewboxScaleYFactor
            );

            return { ...point, pathLength: bezier.length() };
          } else if (isArc(point)) {
            const curves = arcToBezier({
              px: pointsArray[pointsIndex - 1].x,
              py: pointsArray[pointsIndex - 1].y,
              cx: point.x * viewboxScaleXFactor,
              cy: point.y * viewboxScaleYFactor,
              rx: point.curve.rx * viewboxScaleXFactor,
              ry: point.curve.ry * viewboxScaleYFactor,
              xAxisRotation: point.curve.xAxisRotation,
              largeArcFlag: point.curve.largeArcFlag,
              sweepFlag: point.curve.sweepFlag
            });

            const convertedPoints = curves.map((curve, curveIndex, curveArr) => {
              const initialX = curveIndex === 0 ? pointsArray[pointsIndex - 1].x : curveArr[curveIndex - 1].x;
              const initialY = curveIndex === 0 ? pointsArray[pointsIndex - 1].y : curveArr[curveIndex - 1].y;

              const bezier = new Bezier(initialX, initialY, curve.x1, curve.y1, curve.x2, curve.y2, curve.x, curve.y);

              return {
                x: curve.x,
                y: curve.y,
                pathLength: bezier.length(),
                curve: {
                  type: SVG_CURVE_TYPES.CUBIC,
                  x1: curve.x1,
                  y1: curve.y1,
                  x2: curve.x2,
                  y2: curve.y2
                }
              };
            });

            return convertedPoints;
          } else {
            return { ...point, pathLength: getDistanceBetweenPoints(pointsArray[pointsIndex - 1], point) };
          }
        })
        .flatMap(p => p);

      return {
        points,
        totalLength,
        brushSize:
          (parseFloat(stroke.getAttribute('stroke-width') ?? SVG_DEFAULT_STROKE_WIDTH.toString()) ||
            SVG_DEFAULT_STROKE_WIDTH) * Math.max(viewboxScaleXFactor, viewboxScaleYFactor)
      };
    })
    .filter(path => path.totalLength > 0);

  return maskPaths;
};

// Calculate the total length of all the paths
export const totalPathsLength = (paths: MaskPaths) => {
  return paths.reduce((prev, path) => {
    const pointTotal = path.points.reduce((acc, point) => {
      return acc + point.pathLength;
    }, 0);

    return prev + pointTotal;
  }, 0);
};

// Set up the component part for drawing in Pixi (This will be the strokes, fills etc)
interface SetupPixiComponentPartOptions {
  width: number;
  height: number;
  mask: PIXI.Graphics;
  texture: PIXI.Texture;
}
export const setupPixiComponentPart = ({ width, height, mask, texture }: SetupPixiComponentPartOptions) => {
  const pixiElement = new PIXI.Sprite(texture);

  pixiElement.width = width;
  pixiElement.height = height;
  pixiElement.mask = mask;

  return pixiElement;
};

type MaskPaths = {
  points: PointWithPathLength[];
  totalLength: number;
  brushSize: number;
}[];

interface VSVectorImageProps {
  element: ScribeImageElementModel;
  animationTime: number;
  emphasisAnimationTime: number;
  emphasisAnimationLoops: number;
  exitAnimationTime: number;
  pauseTime: number;
  timings: {
    startTimeMs: number;
    endTimeMs: number;
    scribeTotalLengthMs: number;
  };
  playerRef: PIXI.Application;
  imageResource: VSCSVGLoaderResource;
  scene: VSScene;
  revealMaskPaths: MaskPaths;
  strokeMaskPaths: MaskPaths;
}
export default class VSVectorImage extends AnimatableElement {
  strokesMask = new PIXI.Graphics();
  fillsMask = new PIXI.Graphics();

  strokeMaskPaths: MaskPaths;
  revealMaskPaths: MaskPaths;
  strokeMaskPathsTotalLength: number;
  totalAnimationPathLength: number;

  strokesElement: PIXI.Sprite;
  fillsElement: PIXI.Sprite;
  lastDrawToLength = -1;

  constructor({
    element,
    animationTime,
    emphasisAnimationTime,
    emphasisAnimationLoops,
    exitAnimationTime,
    pauseTime,
    timings,
    playerRef,
    imageResource,
    scene,
    revealMaskPaths,
    strokeMaskPaths
  }: VSVectorImageProps) {
    super({
      element,
      playerRef,
      animationTime,
      emphasisAnimationTime,
      emphasisAnimationLoops,
      exitAnimationTime,
      pauseTime,
      timings,
      scene
    });
    if (!element) {
      throw new Error('No element object supplied to VSVectorImage');
    }

    const { strokesTexture, fillsTexture } = imageResource.metadata;
    const fullImageTexture = imageResource.texture;
    const shouldRevealAnimate = shouldRevealAnimation(element);

    // Calculate the mask paths
    this.strokeMaskPaths = strokeMaskPaths;
    this.revealMaskPaths = revealMaskPaths;

    // Get the total length of the paths (used for animation timings)
    this.strokeMaskPathsTotalLength = totalPathsLength(this.strokeMaskPaths);
    this.totalAnimationPathLength = this.strokeMaskPathsTotalLength + totalPathsLength(this.revealMaskPaths);

    // Setup visible elements
    this.strokesElement = shouldRevealAnimate
      ? new PIXI.Sprite() // Blank the strokes sprite if we're reveal animating
      : setupPixiComponentPart({
          width: element.width,
          height: element.height,
          mask: this.strokesMask,
          texture: strokesTexture
        });

    this.fillsElement = setupPixiComponentPart({
      width: element.width,
      height: element.height,
      mask: this.fillsMask,
      texture: shouldRevealAnimate && fullImageTexture ? fullImageTexture : fillsTexture
    });

    this.moveCursorToFirstStrokePoint(this.strokeMaskPaths, this.cursor);

    // Setup the PIXI stage element with all the component parts
    this.stageElement = this.createStageElement(
      [this.fillsElement, this.fillsMask, this.strokesElement, this.strokesMask, this.cursor],
      element
    );

    if (this.stageElement) {
      this.scaleHolder = this.stageElement.getChildAt(0) as PIXI.Container;
      if (element[ENTRANCE_TWEEN_DOCUMENT_KEY]) {
        this.clearMasksFromStage();
        if (!this.hasExitMask) {
          this.stageElement.mask = null;
        }
        this.clearCursorFromCanvas();
      }

      this.stageElement.name = element.id;
      this.animationTime = animationTime;
      this.emphasisAnimationTime = emphasisAnimationTime;
      this.exitAnimationTime = exitAnimationTime;
      this.pauseTime = pauseTime;
    }
  }

  static async create({
    element,
    animationTime,
    emphasisAnimationTime,
    emphasisAnimationLoops,
    exitAnimationTime,
    pauseTime,
    timings,
    playerRef,
    imageResource,
    scene
  }: Omit<VSVectorImageProps, 'revealMaskPaths' | 'strokeMaskPaths'>) {
    const { splitSVGData, pixels, isVectorizedImage } = imageResource.metadata;
    const hasColorsAndStrokes = !!splitSVGData.svgParts.strokes.length && !!splitSVGData.svgParts.fills.length;
    const hasColorsAndNoRevealPaths = !splitSVGData.svgParts.revealPaths.length && !!splitSVGData.svgParts.fills.length;
    const [minX, minY, viewBoxOffsetX, viewBoxOffsetY] = splitSVGData.viewBox.split(' ');

    const viewboxScaleXFactor = element.width / Number(viewBoxOffsetX);
    const viewboxScaleYFactor = element.height / Number(viewBoxOffsetY);
    const shouldRevealAnimate = shouldRevealAnimation(element);

    let strokeMaskPaths: MaskPaths = [];

    if (!shouldRevealAnimate) {
      strokeMaskPaths = isVectorizedImage
        ? await generateFallbackRevealPath({ element, pixels })
        : setupMaskPaths(
            splitSVGData.svgParts.strokes,
            Number(minX),
            Number(minY),
            viewboxScaleXFactor,
            viewboxScaleYFactor
          );
    }

    const revealMaskPaths =
      shouldRevealAnimate || hasColorsAndNoRevealPaths
        ? await generateFallbackRevealPath({
            element,
            pixels,
            dontIllustrate: hasColorsAndStrokes
          })
        : setupMaskPaths(
            splitSVGData.svgParts.revealPaths,
            Number(minX),
            Number(minY),
            viewboxScaleXFactor,
            viewboxScaleYFactor
          );

    return new VSVectorImage({
      element,
      animationTime,
      emphasisAnimationTime,
      emphasisAnimationLoops,
      exitAnimationTime,
      pauseTime,
      timings,
      playerRef,
      imageResource,
      scene,
      revealMaskPaths,
      strokeMaskPaths
    });
  }

  createStageElement = (children: Array<PIXI.DisplayObject>, editorElement: ScribeImageElementModel) => {
    const groupedImage = new PIXI.Container();
    groupedImage.addChild(...children);
    groupedImage.filters = [new PIXI.filters.AlphaFilter(editorElement.opacity ?? 1)];
    const stageElement = applyElementPositions(this.setUpNestedClips(groupedImage), editorElement, this.scaleHolder);
    return stageElement;
  };

  moveCursorToFirstStrokePoint(strokeMaskPaths: MaskPaths, cursor: PIXI.Container) {
    const firstStrokePoint =
      strokeMaskPaths && strokeMaskPaths[0] && strokeMaskPaths[0].points && strokeMaskPaths[0].points[0];
    if (this.cursor && cursor) {
      this.cursor.x = firstStrokePoint ? firstStrokePoint.x : cursor.x;
      this.cursor.y = firstStrokePoint ? firstStrokePoint.y : cursor.y;
    }
  }

  revealWholeStrokes = () => {
    this.strokesElement.visible = true;
    this.strokesElement.mask = null;
    this.strokesMask.visible = false;
  };

  revealWholeFills = () => {
    this.fillsElement.visible = true;
    this.fillsElement.mask = null;
    this.fillsMask.visible = false;
  };

  hideWholeFills = () => {
    this.fillsElement.visible = false;
    this.fillsElement.mask = null;
    this.fillsMask.visible = false;
  };

  hideWholeStrokes = () => {
    this.strokesElement.visible = false;
    this.strokesElement.mask = null;
    this.strokesMask.visible = false;
  };

  revealWholeImage = () => {
    this.revealWholeFills();
    this.revealWholeStrokes();
  };

  hideWholeImage = () => {
    this.hideWholeFills();
    this.hideWholeStrokes();
  };

  setupStrokesForRevealing = () => {
    this.strokesElement.visible = true;
    this.strokesElement.mask = this.strokesMask;
    this.strokesMask.visible = true;
  };

  setupFillsForRevealing = () => {
    this.fillsElement.visible = true;
    this.fillsElement.mask = this.fillsMask;
    this.fillsMask.visible = true;
  };

  clearMasksFromStage() {
    super.clearMasksFromStage();
    this.revealWholeImage();
  }

  manageVisibility = (percentProgress: number, isDrawingStrokes: boolean) => {
    const shouldRevealWhole = !this.shouldAnimateReveal || percentProgress >= 1;
    if (shouldRevealWhole) {
      this.revealWholeImage();
      return true;
    }

    const shouldHideAllElements = percentProgress <= 0;
    if (shouldHideAllElements) {
      this.hideWholeImage();
      return true;
    }

    if (isDrawingStrokes) {
      this.hideWholeFills();
      this.setupStrokesForRevealing();
    } else {
      this.revealWholeStrokes();
      this.setupFillsForRevealing();
    }
  };

  revealAnimation(percentProgress: number) {
    const { isDrawingStrokes, drawToLength } = getCurrentStrokeAnimationValues(
      percentProgress,
      STROKE_ANIMATION_SEGMENT_PERCENTAGE,
      this.strokeMaskPathsTotalLength,
      this.totalAnimationPathLength
    );

    const shouldReturnEarly = this.manageVisibility(percentProgress, isDrawingStrokes);
    if (shouldReturnEarly) return;

    if (drawToLength < this.lastDrawToLength) {
      this.lastDrawToLength = 0;
    }

    if (drawToLength !== this.lastDrawToLength && drawToLength > 0) {
      const drawCommands = isDrawingStrokes ? this.strokeMaskPaths : this.revealMaskPaths;
      const mask = isDrawingStrokes ? this.strokesMask : this.fillsMask;
      let hasDrawnUpTo = isDrawingStrokes ? 0 : this.strokeMaskPathsTotalLength;

      mask.clear();

      drawCommands.some(stroke => {
        mask.lineStyle({
          width: stroke.brushSize,
          color: MASK_LINE_COLOR,
          cap: PIXI.LINE_CAP.ROUND,
          join: PIXI.LINE_JOIN.ROUND
        });

        return stroke.points.some((point, i, arr) => {
          if (hasDrawnUpTo + point.pathLength < drawToLength || point.moveTo === true) {
            if (point.moveTo) {
              mask.moveTo(point.x, point.y);
            } else {
              if (isCubicBezier(point)) {
                if (point.curve.type === SVG_CURVE_TYPES.CUBIC) {
                  mask.bezierCurveTo(point.curve.x1, point.curve.y1, point.curve.x2, point.curve.y2, point.x, point.y);
                }
              } else {
                mask.lineTo(point.x, point.y);
              }
            }
            hasDrawnUpTo += point.pathLength;

            // Allow .some() method to carry on
            return false;
          } else {
            // If we've got here then the this is the last point to draw,
            // but we need to determine where we need to draw up to in this tick
            const distanceToDrawUpToPercentage = (drawToLength - hasDrawnUpTo) / point.pathLength;
            const lastPoint = arr[i - 1];

            if (isCubicBezier(point) || isQuadraticBezier(point)) {
              if (isCubicBezier(point)) {
                const bezier = new Bezier(
                  lastPoint.x,
                  lastPoint.y,
                  point.curve.x1,
                  point.curve.y1,
                  point.curve.x2,
                  point.curve.y2,
                  point.x,
                  point.y
                );
                const curveUpToThisPoint = bezier.split(distanceToDrawUpToPercentage);

                mask.bezierCurveTo(
                  curveUpToThisPoint.left.points[1].x,
                  curveUpToThisPoint.left.points[1].y,
                  curveUpToThisPoint.left.points[2].x,
                  curveUpToThisPoint.left.points[2].y,
                  curveUpToThisPoint.left.points[3].x,
                  curveUpToThisPoint.left.points[3].y
                );

                this.cursor.x = curveUpToThisPoint.left.points[3].x;
                this.cursor.y = curveUpToThisPoint.left.points[3].y;
              }
              if (isQuadraticBezier(point)) {
                const bezier = new Bezier(lastPoint.x, lastPoint.y, point.curve.x1, point.curve.y1, point.x, point.y);
                const curveUpToThisPoint = bezier.split(distanceToDrawUpToPercentage);

                mask.quadraticCurveTo(
                  curveUpToThisPoint.left.points[1].x,
                  curveUpToThisPoint.left.points[1].y,
                  curveUpToThisPoint.left.points[2].x,
                  curveUpToThisPoint.left.points[2].y
                );

                this.cursor.x = curveUpToThisPoint.left.points[2].x;
                this.cursor.y = curveUpToThisPoint.left.points[2].y;
              }
            } else {
              const lineToUpToThisPoint = getPointAlongHypAtPercentage(lastPoint, point, distanceToDrawUpToPercentage);

              mask.lineTo(lineToUpToThisPoint.x, lineToUpToThisPoint.y);

              this.cursor.x = lineToUpToThisPoint.x;
              this.cursor.y = lineToUpToThisPoint.y;
            }

            // If we've reached here then we've drawn the last point we need
            // to so stop the .some() method and resolve the cursor point for the pen
            return true;
          }
        });
      });
    }
  }
}
