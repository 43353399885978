import { AccountType, UploadProvider } from 'js/types';

export const ALLOWED_CURSORS = [
  1985869848,
  2818514498,
  2818551707,
  2818895905,
  2818915634,
  1538297577,
  1538520189,
  1538833152,
  1538895866,
  1538992544,
  1539874627,
  1540822137,
  1543038223,
  1543372001,
  1559115778,
  1559314091,
  1559536005,
  1559652082,
  1559725062,
  1559829441,
  1560188378,
  1560288762,
  1560348975,
  1973101339,
  469135305,
  469456369,
  469527076,
  469786086,
  469891101,
  470042200,
  470110142,
  470368049,
  470568483,
  470705799,
  470893795,
  470974887,
  471146342,
  471220902,
  471450673,
  471570340,
  471704564,
  877189253,
  877334156,
  877546198,
  877762638,
  877957755,
  878299378,
  878596102,
  879456162,
  473372793,
  473442805,
  473619734,
  473683409,
  473745813,
  473832416,
  473903651,
  473965684,
  474040215,
  474261300,
  474341389,
  474420398,
  474657751,
  854495034,
  474734399,
  474808996,
  474905398,
  476025574,
  480933353,
  481028879,
  481253237,
  481384942,
  481504945,
  481678521,
  481759983,
  481839251,
  481991487,
  482087744,
  482392291,
  482788525,
  482857199,
  483071039,
  483165630,
  483260769,
  483356137,
  483448276,
  483524415,
  483616857,
  483723799,
  483805553,
  483889828,
  483975159,
  484572715,
  485301758,
  485687526,
  485799430,
  485903302,
  485994281,
  486082920,
  487058703,
  487183295,
  487261574,
  487357357,
  487451610,
  487547224,
  487630620,
  487715396,
  487839863,
  488530445,
  489054627,
  489220184,
  489336260,
  489513178,
  489616428,
  489702498,
  489829657,
  489919518,
  490013321,
  490147995,
  490254630,
  490362585,
  490445442,
  490703488,
  491602842,
  491983659,
  492070422,
  492217482,
  492319948,
  492430861,
  492537315,
  492642811,
  492726815,
  492811811,
  492890161,
  492983919,
  493071219,
  493161937,
  493236116,
  493320629,
  493483145,
  493786076,
  493865804,
  493941033,
  494039019,
  494256771,
  551688715,
  551785554,
  551864563,
  551952979,
  552044439,
  552114954,
  552188732,
  552261587,
  552346986,
  552417017,
  552502023,
  552896459,
  553016700,
  553117016,
  553212124,
  553407110,
  553505007,
  553598506,
  553806417,
  553894022,
  553989928,
  554065044,
  554163764,
  554344483,
  1112369282,
  556800958,
  557042106,
  557490851,
  557809864,
  558532396,
  558703203,
  933363413,
  938365157,
  938553731,
  938665600,
  559095209,
  559529740,
  559676264,
  940058316,
  940135067,
  940227753,
  940304158,
  940393060,
  940502425,
  940575613,
  568695184,
  568787070,
  568869995,
  568969864,
  569051099,
  569140744,
  569232057,
  569308885,
  569387304,
  576667276,
  576768998,
  576848313,
  857729675,
  861233562,
  876295810,
  876542274
];

export const ALLOWED_ERASE_CURSORS = [
  1070239572,
  469960781,
  470705799,
  473745813,
  474170097,
  480933353,
  482212155,
  483071039,
  484426276,
  485903302,
  487959650,
  489336260,
  490603374,
  492319948,
  493392515,
  494256771,
  552417017,
  553016700,
  554258184,
  557809864,
  558615362,
  559529740,
  560260323,
  569051099,
  569482447
];

export const ALLOWED_DRAG_CURSORS = [
  470233195,
  470297927,
  474540166,
  474598171,
  485227866,
  484670714,
  488196634,
  488089812,
  490781866,
  857848099,
  876704942,
  560526393,
  560454707,
  554461303,
  558791865,
  554548907,
  552645542,
  552588805,
  493636505,
  493576398,
  1027156910,
  482477465,
  471934964
];

export const ACCOUNT_TYPES: Record<AccountType, AccountType> = {
  FREE: 'FREE',
  PLUS: 'PLUS',
  PRO: 'PRO',
  EDU: 'EDU',
  LITE: 'LITE'
};

export { ACCOUNT_TYPES as ROLES };

export enum SCOPE {
  searchNounProject = 'searchNounProject',
  createLongVideo = 'createLongVideo',
  addCustomFont = 'addCustomFont',
  createPreviewLink = 'createPreviewLink'
}

export const APP_DATA_KEYS = {
  FREE_DOWNLOAD_COUNT: 'freedlcount',
  USER_SETTINGS: 'usersettings'
};

export const MP4_FILE_FORMAT = '720MP4_H264';
export const GIF_FILE_FORMAT = '420GIF_12FPS';

export const FILE_FORMATS = [
  {
    key: MP4_FILE_FORMAT,
    title: 'MP4',
    name: MP4_FILE_FORMAT,
    id: MP4_FILE_FORMAT,
    label: 'MP4',
    info: 'Long videos with full colors & audio. Higher file size'
  },
  {
    key: GIF_FILE_FORMAT,
    title: 'GIF',
    name: GIF_FILE_FORMAT,
    id: GIF_FILE_FORMAT,
    label: 'GIF',
    info: 'Please note that GIFs are limited to the first 15 seconds of your project'
  }
];

export const maxLibraryImageUploadRetries = 5;

export const maxUserImageUploadRetries = 3;

export const maxImageUploadOnScribeLoadRetries = 1;

export const APP_TOOLTIP_ID = 'root-tooltip';

export const IMAGE_PROVIDER_TYPES: Record<string, UploadProvider> = {
  UPLOAD: 'upload',
  USER: 'user',
  LIBRARY: 'library',
  NOUN_PROJECT: 'noun-project',
  AI_LIBRARY: 'ai-library'
};

export const ROSIE_FINELINE_ID = 4820;
export const DEFAULT_HAND_CURSOR_ID = 'default';
export const DEFAULT_PEN_CURSOR_ID = 'default-pen';
export const CURSOR_TYPES = {
  PEN: 'pen',
  HAND: 'hand'
};

export const DEFAULT_ERASE_CURSOR_ID = 'default-erase';
export const DEFAULT_DRAG_CURSOR_ID = 'default-drag';

// Base Animation values before camera scaling
export const SHAKE_LOOP_DISTANCE = 80;
export const BOUNCE_LOOP_DISTANCE = 80;

// Entrance Keys
export const NO_ANIMATION_KEY = 'none';
export const DRAW_KEY = 'draw';
export const PEN_DRAW_KEY = `pen-draw`;
export const HAND_DRAW_KEY = `hand-draw`;

export const FADE_IN_KEY = 'fade-in';
export const MOVE_IN_KEY = 'move-in';
export const BOUNCE_IN_KEY = 'bounce-in';
export const DRAG_IN_KEY = 'drag-in';

// Emphasis Keys
export const BOUNCE_LOOP_KEY = 'bounce-loop';
export const SPIN_LOOP_KEY = 'spin-loop';
export const PULSE_LOOP_KEY = 'pulse-loop';
export const SHAKE_LOOP_KEY = 'shake-loop';

// Exit Keys
export const FADE_OUT_KEY = 'fade-out';
export const MOVE_OUT_KEY = 'move-out';
export const SLINGSHOT_KEY = 'slingshot';
export const DISAPPEAR_KEY = 'disappear';
export const EXIT_ANIMATION_ERASE_KEY = 'exit-erase';

export const ENTRANCE_STAGE_KEY = 'entrance';
export const EMPHASIS_STAGE_KEY = 'emphasis';
export const EXIT_STAGE_KEY = 'exit';

export const ENTRANCE_TWEEN_DOCUMENT_KEY = 'entranceTween';
export const EMPHASIS_TWEEN_DOCUMENT_KEY = 'emphasisTween';
export const EXIT_TWEEN_DOCUMENT_KEY = 'exitTween';

export const ENTRANCE_ANIMATION_DOCUMENT_KEY = 'entranceAnimation';
export const EMPHASIS_ANIMATION_DOCUMENT_KEY = 'emphasisAnimation';
export const EXIT_ANIMATION_DOCUMENT_KEY = 'exitAnimation';
export const CAMERA_EASING_EASEOUT = 'power2.out';
export const CAMERA_EASING_LINEAR = 'none';
export const CAMERA_EASING_NONE = 'no-ease-no-duration';

export const REVERSE_ICON_LIST = [MOVE_OUT_KEY, SLINGSHOT_KEY, SHAKE_LOOP_KEY];

export const DIRECTIONAL_ANIMATION_LIST = [MOVE_IN_KEY, BOUNCE_IN_KEY, MOVE_OUT_KEY, SLINGSHOT_KEY];

export const NO_DURATION_ANIMATIONS = [DISAPPEAR_KEY];

// Scene transition keys
export const SCENE_TRANSITION_KEY_NONE = 'none';
export const SCENE_TRANSITION_KEY_SLIDE = 'slide';
export const SCENE_TRANSITION_KEY_BOUNCE = 'bounce';
export const SCENE_TRANSITION_KEY_FADE = 'fade';
export const SCENE_TRANSITION_KEY_ERASE = 'erase';
export const SCENE_TRANSITION_KEY_DRAG_ON = 'drag-on';

export const SCENE_TRANSITIONS_WITH_DIRECTION = [
  SCENE_TRANSITION_KEY_SLIDE,
  SCENE_TRANSITION_KEY_BOUNCE,
  SCENE_TRANSITION_KEY_DRAG_ON
];

export const COGNITO_GROUP_TEMPLATE_MANAGER = 'vsc-template-manager';
export const ACCESS_TOKEN_HEADER_KEY = 'x-spk-access-token';

export const SCENE_TRANSITION_NO_CURSOR_ID = 'no-cursor';

// Renderer 429 status error string
export const RENDERER_RATE_LIMIT_ERROR_STRING = 'Status: 429';
export const DEFAULT_TEXT_ELEMENT_COPY = 'Enter text here ...';

export const NO_CURSOR_ID = -1;

export const FILE_CONTENT_TYPES = {
  SVG: 'image/svg+xml',
  GIF: 'image/gif',
  PNG: 'image/png',
  JPG: 'image/jpeg',
  BMP: 'image/bmp'
};

export const KEYBOARD_TRIGGER = 'Keyboard';
export const EDIT_PANEL_TRIGGER = 'Edit Panel';
export const ON_ELEMENT_TRIGGER = 'Element on Canvas';
export const ARROWS_TRIGGER = 'Arrows';
export const INPUT_FIELD_TRIGGER = 'Input Field';

export const CAROUSEL_MARGIN = 20;

export const VIEW_ALL_LINK_LABEL = 'View all';
export const DELETE_SCRIBE_CONFIRM_BUTTON_LABEL = 'Ok, delete the project';
export const PROJECT_DELETED_FLASH_TITLE = 'Project deleted:';

export const NEW_AUDIO_BUTTON_ID = 'new-audio';
export const ACTIONBAR_OPTIONS_BUTTON_ID = 'optionsButton';
export const ACTIONBAR_PLAY_SCENE_BUTTON_ID = 'playSceneButton';

export const MAX_ANIMATION_TIME_SECONDS = 100;
export const ADVANCED_TIMELINE_HTML_ID = 'Advanced_Timeline';
export const CONTEXT_MENU_BACKDROP_HTML_ID = 'context-backdrop';
export const AUDIO_BUTTON_HTML_ID = 'showLeftAudioPanel';
export const LEFT_AUDIO_PANEL_HTML_ID = 'LeftAudioPanel';
export const TIMELINE_SWITCH_HTML_ID = 'timelineSwitch__container';

export const VIDEOSCRIBE_GROUPS = {
  PRO: 'videoscribe-pro',
  EDU: 'videoscribe-edu',
  PLUS: 'videoscribe-plus',
  LITE: 'videoscribe-lite'
};

export const IMAGE_GEN_STYLES = {
  HIGHLIGHT: 'videoscribe-classic-flux-schnell-2024-11-28',
  COLOR: 'videoscribe-colour-flux-schnell-2024-11-28'
};
