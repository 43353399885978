import * as PIXI from 'pixi.js';

export const renderCharacterPixels = (renderer: PIXI.Renderer, singleChar: PIXI.Text | PIXI.BitmapText) => {
  return new Promise<Uint8Array>(resolve => {
    renderer.once('postrender', () => {
      const pixels = renderer.plugins.extract.pixels(singleChar);
      resolve(pixels);
    });
    renderer.render(singleChar);
  });
};
