import {
  UPDATE_EDITOR_POSITION_ZOOM,
  pinActiveCameras,
  setFirstLoad,
  unpinActiveCameras,
  updateEditorPositionAndZoom,
  setSelectedTextColor,
  setActiveElements,
  updateSceneElements,
  updateSceneThumbnail,
  setIsTextboxEditing,
  setCustomTextStyle
} from 'js/actionCreators/scribeActions';
import { ON_ELEMENT_TRIGGER } from 'js/config/consts';
import { MAX_SCROLL_MULTIPLIER } from 'js/config/defaults';
import { useTypedSelector } from 'js/selectors/typedUseSelector';
import { ScribeScene, ScribeSettings, VSElementModel, LeftHandPanel } from 'js/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTextFieldDimensions, UpdatedTextElementConfig } from 'js/actionCreators/elementActions';

import ProjectCanvas from './ProjectCanvas';
export const MAX_CANVAS_MULTIPLIER = 3;
interface UseEditorCanvasProps {
  activeElements: Array<string>;
  canvasSize: {
    width: number;
    height: number;
  };
  elements: VSElementModel[];
  onUpdateZoom: (zoom: number) => void;
  onUpdateMinZoom: (minZoom: number) => void;
  onUpdateScrollbars: (dimensions: {
    width: number;
    height: number;
    verticalOffset: number;
    horizontalOffset: number;
  }) => void;

  onViewportDimensionsChange: (viewportDimensions: { width: number; height: number }) => void;
  projectSettings: ScribeSettings;
  scene?: ScribeScene;
  canvas: React.MutableRefObject<HTMLCanvasElement | null>;
  canvasWrapper: React.MutableRefObject<HTMLDivElement | null>;
  projectId: number;
}

let editorCanvas: ProjectCanvas | undefined;

export const getEditorCanvas = () => {
  return editorCanvas;
};

export function useEditorCanvas({
  canvas,
  elements,
  scene,
  activeElements,
  projectSettings,
  onUpdateZoom,
  onUpdateMinZoom,
  onUpdateScrollbars,
  onViewportDimensionsChange,
  canvasSize,
  canvasWrapper,
  projectId
}: UseEditorCanvasProps) {
  const dispatch = useDispatch();
  const gridlines = useTypedSelector(state => state.ui.grid);
  const firstLoad = useTypedSelector(state => state.scribes.firstLoad);
  const canvasDragOn = useTypedSelector(state => state.ui.canvasDragOn);
  const isPixiTimeline = useTypedSelector(state => state.ui.newTimeline);
  const openedLeftHandPanel = useTypedSelector(state => state.ui.leftHandPanel);
  const transformState = useTypedSelector(state => state.ui.editorTransform);
  const customTextStyleToBeSet = useTypedSelector(state => state.scribes.customTextStyleToBeSet);
  const isLeftPanelOpen = openedLeftHandPanel === LeftHandPanel.SCENES || openedLeftHandPanel === LeftHandPanel.AUDIO;
  let transform: number[] | undefined;
  if (transformState && scene?.id) {
    transform = transformState[scene.id];
  }

  useEffect(() => {
    if (canvas.current && canvasWrapper.current && !editorCanvas && scene) {
      const onSetSelectedTextColor = (color: string | null | undefined) => dispatch(setSelectedTextColor(color));
      const handleUnpinActiveCameras = () => {
        dispatch(unpinActiveCameras(projectId, ON_ELEMENT_TRIGGER));
      };
      const handleSetFirstLoad = (value: boolean) => {
        dispatch(setFirstLoad(value));
      };
      const handlePinActiveCameras = () => {
        dispatch(pinActiveCameras(projectId, ON_ELEMENT_TRIGGER));
      };
      const handleOnEditorUpdatedPosOrZoom = (vpTransform: number[]) => {
        dispatch(updateEditorPositionAndZoom(projectId, scene.id, vpTransform, UPDATE_EDITOR_POSITION_ZOOM));
      };
      const handleUpdateTextfieldDimensions = (updatedElementsConfig: Array<UpdatedTextElementConfig>) => {
        dispatch(updateTextFieldDimensions(projectId, updatedElementsConfig));
      };
      const onSetActiveElements = (elementIds: string[]) => dispatch(setActiveElements(elementIds));

      const onUpdateScene = (elements: Array<VSElementModel>, thumbnail: string) =>
        dispatch(updateSceneElements(elements, thumbnail, projectId, scene.id));
      const onVisualUpdate = (thumbnail: string) => dispatch(updateSceneThumbnail(thumbnail, projectId));
      const handleSetIsTextboxEditing = (isEnabled: boolean) => dispatch(setIsTextboxEditing(isEnabled));

      const { width, height } = canvasWrapper.current.getBoundingClientRect();

      editorCanvas = new ProjectCanvas(
        canvas.current,
        {
          scene,
          elements,
          activeElements,
          projectSettings,
          gridlines,
          editorTransform: transform,
          canvasDragOn,
          firstLoad,
          isPixiTimeline
        },
        {
          initialViewportSize: {
            width,
            height
          },
          maxCanvasMultiplyer: MAX_CANVAS_MULTIPLIER,
          maxScrollMultiplyer: MAX_SCROLL_MULTIPLIER,
          onVisualUpdate,
          onUpdateScene,
          onSetActiveElements,
          onUpdateZoom,
          onUpdateMinZoom,
          onUpdateScrollbars,
          onPinActiveCameras: handlePinActiveCameras,
          onUnpinActiveCameras: handleUnpinActiveCameras,
          onSetFirstLoad: handleSetFirstLoad,
          onUpdatePosOrZoom: handleOnEditorUpdatedPosOrZoom,
          onUpdateTextfieldDimensions: handleUpdateTextfieldDimensions,
          onSetSelectedTextColor,
          handleSetIsTextboxEditing,
          canvasSize
        }
      );

      editorCanvas.addElementsPromise = editorCanvas.addElements().then(() => {
        editorCanvas?.applyElementsStackingOrder();
      });
    } else if (editorCanvas && scene) {
      editorCanvas.updateProps({
        firstLoad,
        scene,
        elements,
        activeElements,
        projectSettings,
        gridlines,
        canvasDragOn,
        isPixiTimeline,
        isLeftPanelOpen
      });
    }
  }, [
    activeElements,
    canvas,
    canvasSize,
    canvasWrapper,
    dispatch,
    elements,
    projectId,
    projectSettings,
    scene,
    onUpdateScrollbars,
    onUpdateZoom,
    onUpdateMinZoom,
    gridlines,
    firstLoad,
    canvasDragOn,
    transform,
    isPixiTimeline,
    isLeftPanelOpen
  ]);

  useEffect(() => {
    if (canvasWrapper.current && editorCanvas) {
      const resizeObserver = new ResizeObserver(entries => {
        if (entries[0] && editorCanvas) {
          const { width, height } = entries[0].contentRect;
          editorCanvas.updateSize(entries);
          onViewportDimensionsChange({
            width,
            height
          });
        }
      });
      resizeObserver.observe(canvasWrapper.current);

      const viewportRect = canvasWrapper.current.getBoundingClientRect();
      onViewportDimensionsChange({
        width: viewportRect.width,
        height: viewportRect.height
      });

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [canvasWrapper, onViewportDimensionsChange]);

  // Setting custom text style triggered from store
  useEffect(() => {
    if (!editorCanvas || !customTextStyleToBeSet) return;
    for (const key in customTextStyleToBeSet) {
      const customTextStyleKey = key as keyof typeof customTextStyleToBeSet;
      editorCanvas.updateCustomTextStyle(customTextStyleKey, customTextStyleToBeSet[customTextStyleKey]);
    }
    dispatch(setCustomTextStyle(null));
  }, [customTextStyleToBeSet, dispatch]);

  useEffect(() => {
    return () => {
      if (editorCanvas) {
        editorCanvas.dispose();
        editorCanvas = undefined;
      }
    };
  }, []);

  return editorCanvas;
}
