import {
  TRACK_UPGRADE_SUBSCRIPTION_BUTTON_CLICKED,
  trackUpgradeSubscriptionButtonClicked as trackUpgradeSubscriptionButtonClickedAction,
  TrackUpgradeSubscriptionButtonClickedAction,
  trackPurchaseSubscriptionButtonClicked as trackPurchaseSubscriptionButtonClickedAction,
  TRACK_PURCHASE_SUBSCRIPTION_BUTTON_CLICKED,
  TrackPurchaseSubscriptionButtonClickedAction
} from 'js/actionCreators/trackingActions';
import { UPGRADE_ACCOUNT, UpgradeAccountAction } from 'js/actionCreators/userAccountAction';
import { RootState, ExistingScribeModel, ScribeSource } from 'js/types';
import { ACCOUNT_TYPES } from 'js/config/consts';
import UserModel from 'js/shared/helpers/app-services/UserModel';
import { call, takeEvery, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects';

import { getScribeById } from '../../selectors';
import { track } from '../mixpanelProvider';

interface TrackScribeData {
  'Scribe ID': number;
  'Scribe Type': ScribeSource['type'] | undefined;
}

function* getScribeData(): Generator<
  SelectEffect,
  TrackScribeData | undefined,
  string | ExistingScribeModel | undefined
> {
  const triggerPath = (yield select(({ router }: RootState) => router.location.pathname)) as string;
  const match = triggerPath.match(/\/editor\/(.+)/);

  if (!match?.[1]) return;
  const scribe = (yield select((state: RootState) => getScribeById(state, match[1]))) as
    | ExistingScribeModel
    | undefined;
  if (!scribe) return;
  return {
    'Scribe ID': scribe.id,
    'Scribe Type': scribe.source?.type
  };
}

export function* trackUpgradeSubscriptionButtonClicked({
  term,
  tier,
  eventTrigger,
  extraEventProps
}: TrackUpgradeSubscriptionButtonClickedAction): Generator<
  SelectEffect | CallEffect,
  void,
  TrackScribeData | undefined
> {
  const scribeData = yield call(getScribeData);
  yield call(
    track,
    `Click Upgrade Subscription Tier Button`,
    {
      ...scribeData,
      'Subscription Tier': tier,
      'Subscription Term': term,
      'Event Trigger': eventTrigger,
      ...extraEventProps
    },
    { send_immediately: true }
  );
}

export function* trackPurchaseSubscriptionButtonClicked({
  term,
  tier,
  eventTrigger,
  extraEventProps
}: TrackPurchaseSubscriptionButtonClickedAction): Generator<
  SelectEffect | CallEffect,
  void,
  TrackScribeData | undefined
> {
  const scribeData = yield call(getScribeData);
  yield call(
    track,
    `Click Purchase Subscription Tier Button`,
    {
      ...scribeData,
      'Subscription Tier': tier,
      'Subscription Term': term,
      'Event Trigger': eventTrigger,
      ...extraEventProps
    },
    { send_immediately: true }
  );
}

export function* trackUpgradeSubscriptionTriggered({
  trigger,
  extraEventProps
}: UpgradeAccountAction): Generator<
  SelectEffect | PutEffect<TrackUpgradeSubscriptionButtonClickedAction | TrackPurchaseSubscriptionButtonClickedAction>,
  void,
  UserModel
> {
  const { accountType, term } = yield select(state => state.auth.currentUser);
  switch (accountType) {
    case ACCOUNT_TYPES.LITE:
      yield put(trackUpgradeSubscriptionButtonClickedAction(accountType, term, trigger, extraEventProps));
      break;
    case ACCOUNT_TYPES.FREE:
      yield put(trackPurchaseSubscriptionButtonClickedAction(accountType, term, trigger, extraEventProps));
      break;
  }
}

export default function* trackSubscriptionSagas() {
  yield takeEvery(TRACK_UPGRADE_SUBSCRIPTION_BUTTON_CLICKED, trackUpgradeSubscriptionButtonClicked);
  yield takeEvery(TRACK_PURCHASE_SUBSCRIPTION_BUTTON_CLICKED, trackPurchaseSubscriptionButtonClicked);
  yield takeEvery(UPGRADE_ACCOUNT, trackUpgradeSubscriptionTriggered);
}
