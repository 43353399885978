import { RenderProgressSuccessResult } from 'js/types';

export const DOWNLOAD_RESET = 'DOWNLOAD_RESET';
export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST';
export const FETCH_RENDER_STATUS_FAILED = 'FETCH_RENDER_STATUS_FAILED';
export const POLLING_CANCELLED = 'POLLING_CANCELLED';
export const UPDATE_FREE_DOWNLOAD_COUNT = 'UPDATE_FREE_DOWNLOAD_COUNT';
export const UPDATE_LITE_DOWNLOAD_DETAILS = 'UPDATE_LITE_DOWNLOAD_DETAILS';
export const UPDATE_DOWNLOAD_COUNT_FAILED = 'UPDATE_DOWNLOAD_COUNT_FAILED';
export const FETCH_RENDER_PROGRESS_SUCCESS = 'FETCH_RENDER_PROGRESS_SUCCESS';
export const ABORT_DOWNLOAD_USER_ACTION = 'ABORT_DOWNLOAD_USER_ACTION';
export const DOWNLOAD_SUCCESS_UPSELL_ACTION = 'DOWNLOAD_SUCCESS_UPSELL_ACTION';

export interface RequestDownloadAction {
  type: typeof DOWNLOAD_REQUEST;
  scribeId: number;
  format: string;
}

export const requestDownload = (scribeId: number, format: string): RequestDownloadAction => ({
  type: DOWNLOAD_REQUEST,
  scribeId,
  format
});

export { DOWNLOAD_REQUEST_SUCCESS, downloadRequestSuccess } from './downloadRequestSuccessAction';
export { DOWNLOAD_REQUEST_FAILED, downloadRequestFailed } from './downloadRequestFailedAction';

export const downloadReset = () => ({ type: DOWNLOAD_RESET });

export interface FetchRenderStatusFailedAction {
  type: typeof FETCH_RENDER_STATUS_FAILED;
  error: Error;
  shortId: number;
}

export const fetchRenderStatusFailed = (error: Error, shortId: number): FetchRenderStatusFailedAction => ({
  type: FETCH_RENDER_STATUS_FAILED,
  error,
  shortId
});

export { START_RENDER_STATUS_POLLING, startRenderStatusPolling } from './startRenderStatusPollingAction';
export { STOP_RENDER_STATUS_POLLING, stopRenderStatusPolling } from './stopRenderStatusPollingAction';

export interface PollingCancelledAction {
  type: typeof POLLING_CANCELLED;
  shortId: number;
}

export const pollingCancelled = (shortId: number): PollingCancelledAction => ({
  type: POLLING_CANCELLED,
  shortId
});

export { RENDER_FAILED, renderFailed } from './renderFailedAction';
export { getVideoDownloadUrlSuccess, GET_VIDEO_DOWNLOAD_URL_SUCCESS } from './getVideoDownloadUrlSuccessAction';
export { GET_VIDEO_DOWNLOAD_URL_FAILED, getVideoDownloadUrlFailed } from './getVideoDownloadUrlFailedAction';

export interface UpdateFreeDownloadCountAction {
  type: typeof UPDATE_FREE_DOWNLOAD_COUNT;
  count: number;
}

export const updateFreeDownloadCount = (count: number): UpdateFreeDownloadCountAction => ({
  type: UPDATE_FREE_DOWNLOAD_COUNT,
  count
});

export interface UpdateLiteDownloadDetailsAction {
  type: typeof UPDATE_LITE_DOWNLOAD_DETAILS;
  liteDownloadsRemaining: number;
  downloadResetDate: string;
  liteDownloadLimit: number;
}

export const updateLiteDownloadDetails = (
  downloadsRemaining: number,
  downloadResetDate: string,
  downloadLimit: number
): UpdateLiteDownloadDetailsAction => ({
  type: UPDATE_LITE_DOWNLOAD_DETAILS,
  liteDownloadsRemaining: downloadsRemaining,
  downloadResetDate,
  liteDownloadLimit: downloadLimit
});

export const updateDownloadCountFailed = () => ({
  type: UPDATE_DOWNLOAD_COUNT_FAILED
});

export interface FetchRenderProgressSuccessAction {
  type: typeof FETCH_RENDER_PROGRESS_SUCCESS;
  shortId: number;
  result: RenderProgressSuccessResult;
}

export const fetchRenderProgressSuccess = (
  shortId: number,
  result: RenderProgressSuccessResult
): FetchRenderProgressSuccessAction => ({
  type: FETCH_RENDER_PROGRESS_SUCCESS,
  shortId,
  result
});

export { ABORT_DOWNLOAD, abortDownload } from './abortDownloadAction';

export const abortDownloadUserAction = () => ({
  type: ABORT_DOWNLOAD_USER_ACTION
});

export const downloadSuccessUpsellAction = () => ({
  type: DOWNLOAD_SUCCESS_UPSELL_ACTION
});
