const CHARACTERS_PER_SECOND = 16;
export function voiceoverGenerationDurationEstimate(prompt: string) {
  const seconds = estimateTextLengthInSeconds(prompt);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  let output = '';
  if (minutes > 0) {
    if (minutes === 1) {
      output += `${minutes} minute`;
    } else {
      output += `${minutes} minutes `;
    }
  }
  if (remainingSeconds > 0 || minutes === 0) {
    if (remainingSeconds === 1) {
      output += ` ${remainingSeconds} second`;
    } else {
      output += ` ${remainingSeconds} seconds`;
    }
  }

  return output.trim();
}

export function estimateTextLengthInSeconds(text: string) {
  const characters = Array.from(text.trim()).length;
  const seconds = Math.ceil(characters / CHARACTERS_PER_SECOND);

  return seconds;
}
